@use "../../../../../css/index.scss" as *;
$eInvoice_grey800: #424242;

.table-with-infinite-scroll-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  > .table-with-infinite-scroll-header {
    flex: 0 0 auto;
    > .table-with-infinite-scroll-header-title {
      padding: $large;
      @include h3;
    }
  }
  > .table-with-infinite-scroll-main-container {
    flex: 1 0 auto;
    height: $base;

    > .MuiTableContainer-root {
      min-height: 25rem;
      max-height: 100%;
      overflow-y: overlay;
      white-space: nowrap;
      > .MuiTable-root {
        > .MuiTableHead-root > .MuiTableRow-root {
          > .MuiTableCell-root {
            padding: $base;

            color: #707a8f;
            > .header-cell-group {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              > .header-cell-group-title {
                @include caption;
              }
              > .header-cell-group-icon {
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;

                > svg {
                  height: $medium;
                }
              }
            }
          }
        }
        > .MuiTableBody-root > .MuiTableRow-root {
          user-select: none;
          &:hover {
            background: #f2f3f8;
            cursor: pointer;
          }
          > .MuiTableCell-root {
            border-color: #f0f0f0;
            padding: $medium $base;
            > .infinite-scroll-table-smart-text {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              overflow: hidden;
              > .container {
                width: $base;
                flex: 1 1 auto;
                > .infinite-scroll-table-data-cell {
                  @include body1;
                  color: $eInvoice_grey800;
                }
              }
            }
          }
        }
      }
      &::-webkit-scrollbar {
        height: 0.6rem;
      }
    }

    ::-webkit-scrollbar-track {
      margin-top: 3rem;
    }
  }
}

.email-wrap {
  word-break: break-all;
  white-space: pre-wrap;
}
