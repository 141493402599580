@use "../../../css/base";
@use "../../../css/spacing";
@use "../../../css/typography";

$btn-primary: #143b52;
$btn-primaryHover: #124564;
$btn-primaryDisabled: #143b52;

$btn-secondary: #f7f7fb;
$btn-secondaryHover: #eef0f6;
$btn-secondaryDisabled: #f7f7fb;

$btn-grey: #dee1ed;
$btn-greyHover: #eef0f6;
$btn-greyDisabled: #f7f7fb;

$btn-trans: white;
$btn-transHover: #124564;
$btn-transDisabled: #143b52;

$btn-error: #e54646;
$btn-errorHover: #d91f1f;
$btn-errorDisabled: #d91f1f;

$btn-snippet: #f7f7fb;
$btn-snippetHover: #f7f7fb;
$btn-snippetDisabled: #143b52;

$btn-ghost: #2962ff;
$btn-ghostHover: #0d47a1;
$btn-ghostDisabled: #757575;

// ! Default Buttons
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  cursor: pointer;
  border-radius: spacing.$atom;
  border: none;
  padding: spacing.$small spacing.$medium;
  > .btn-load-wrapper {
    display: none;
  }
}

.btn-grp-tooltip-highlight {
  font-size: 0.875rem;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.25rem;
  padding: 0.15rem 0.375rem;
  line-height: 1.5rem;
  margin-left: 0.5rem;
}

.secondary-border {
  border: spacing.$borderline solid $btn-grey;
}

.icon-btn {
  gap: spacing.$medium;
  padding-left: spacing.$base;
  padding-right: spacing.$base;
  &.btn-sm {
    gap: spacing.$tiny;
  }
  & > svg > path {
    fill: base.$white100;
  }
  &-left {
    padding-right: spacing.$medium;
    &.btn-sm {
      padding-left: spacing.$small;
    }
  }
  &-right {
    padding-left: spacing.$medium;
    &.btn-sm {
      padding-right: spacing.$small;
    }
  }
}

// ! Button Typography
.btn-text {
  font-family: typography.$primaryFontFamily;
  font-weight: 600;
  font-size: 0.875rem;
  margin: 0;
}

.btn-text-sm {
  font-family: typography.$primaryFontFamily;
  font-weight: 600;
  font-size: spacing.$medium;
  margin: 0;
}

// ! Button Sizing
.btn-sm {
  height: spacing.$large;
}

.btn-md {
  height: spacing.$larger;
}

.btn-lg {
  height: spacing.$xlarge;
}

// ! Button Variants
.btn-primary {
  background-color: $btn-primary;
  color: base.$white100;
  &:hover {
    background-color: $btn-primaryHover;
  }
  &:disabled {
    background-color: $btn-primaryDisabled;
    opacity: 0.4;
    cursor: auto;
  }
}

.btn-primary2 {
  background-color: base.$babyBlue;
  color: base.$white100;
  &:hover {
    background-color: base.$babyBlue;
  }
  &:disabled {
    background-color: base.$babyBlue;
    opacity: 0.4;
    cursor: auto;
  }
}

.btn-secondary {
  background-color: $btn-secondary;
  color: base.$primary;
  &:hover {
    background-color: $btn-secondaryHover;
  }
  &:disabled {
    background-color: $btn-secondaryDisabled;
    opacity: 0.5;
    cursor: auto;
  }
  & > svg > path {
    fill: base.$primary;
  }
}

.btn-secondary2 {
  background-color: $btn-secondary;
  color: base.$babyBlue;
  &:hover {
    background-color: $btn-secondaryHover;
  }
  &:disabled {
    background-color: $btn-secondaryDisabled;
    opacity: 0.5;
    cursor: auto;
  }
  & > svg > path {
    fill: base.$babyBlue;
  }
}

.btn-grey {
  background-color: $btn-grey;
  color: base.$primary;
  &:hover {
    background-color: $btn-greyHover;
  }
  &:disabled {
    background-color: $btn-greyDisabled;
    opacity: 0.4;
    cursor: auto;
  }
}

.btn-transparent {
  background-color: $btn-trans;
  color: base.$primary;
  &:hover {
    background-color: $btn-transHover;
    color: base.$white100;
  }
  &:disabled {
    background-color: $btn-transDisabled;
    opacity: 0.4;
    cursor: auto;
  }
}

.btn-snippet {
  background-color: $btn-snippet;
  color: base.$primary;
  &:hover {
    background-color: $btn-snippetHover;
  }
  &:disabled {
    background-color: $btn-snippetDisabled;
    opacity: 0.4;
    cursor: auto;
  }
  & > svg > path {
    fill: base.$primary;
  }
}

.btn-small-padding {
  padding-left: spacing.$small;
  padding-right: spacing.$small;
}

.btn-loading {
  position: relative;
  > :not(.btn-load-wrapper) {
    visibility: hidden;
  }
  > .btn-load-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    &-primary,
    &-transparent {
      > span {
        color: base.$white100;
      }
    }
    &-secondary,
    &-grey {
      > span {
        color: $btn-primary;
      }
    }
  }
}

.btn-error {
  background-color: $btn-error;
  color: base.$white100;
  &:hover {
    background-color: $btn-errorHover;
  }
  &:disabled {
    background-color: $btn-errorDisabled;
    opacity: 0.4;
    cursor: auto;
  }
}

.btn-solo-icon {
  background-color: base.$white100;
  padding-right: spacing.$small;
  padding-left: spacing.$small;
  color: $btn-primary;
  gap: 0;
  & > svg > path {
    fill: base.$grey60;
  }
  &:hover {
    background-color: base.$white100;
    & > svg > path {
      fill: base.$primary;
    }
  }
  &:disabled {
    background-color: base.$white100;
    opacity: 0.4;
    cursor: auto;
    & > svg > path {
      fill: base.$grey60;
    }
  }
}

.btn-ghost {
  background-color: transparent;
  color: $btn-ghost;
  border-radius: spacing.$small;
  border: spacing.$borderline solid $btn-ghost;
  &:hover {
    background-color: $btn-ghostHover;
    color: base.$white100;
    border: spacing.$borderline solid $btn-ghostHover;
  }
}
