@use "../../../css/index.scss" as *;
$eInvoice_grey50: #fafafa;
$eInvoice_grey200: #eee;
$eInvoice_grey600: #757575;
$eInvoice_grey800: #424242;
$eInvoice_grey900: #212121;

// GSP NAVIGATOR STYLES
.gsp-nav-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0;
  > .head {
    flex: 0 0 auto;
    height: 3.5rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    border-bottom: $borderline solid $grey90;
    padding: 0;
    padding-left: $large;
  }

  > .body {
    flex: 1 1 auto;
    height: $base;
    width: 100%;
    padding: $large $large 0 $large;
  }
  > .body.padding-v2 {
    padding: 0 0 $borderline 0;
  }
  > .body.e-invoice {
    padding: 0;
    font-family: $sageFontFamily;
  }
}

.gsp-nav-wrapper.e-invoice {
  width: calc(100% - 17.625rem);
}

// GSP COMPONENT STYLES
.gsp-component-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .gsp-content-header-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: $medium 0.875rem;
    height: 8rem;
    gap: 1rem;
    border: $borderline solid $grey90;
    border-radius: $small;
    margin-bottom: $large;
    > .gsp-query-text {
      height: 1.5rem;
      font-family: $primaryFontFamily;
      font-weight: 600;
      font-size: $fontSizeL;
      line-height: $lineHeightL;
      color: #4d4d4d;
      margin-bottom: $base;
    }
    > .gsp-filter-wrapper {
      @mixin body1 {
        line-height: $lineHeightSM;
      }
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: $medium;
      color: $grey10;
    }
    > .gsp-search-wrapper {
      display: flex;
      height: 3rem;
      width: 50.9375rem;
      padding: $medium 0.875rem;
      gap: $base;
      > .search-icon {
        margin-top: $tiny;
      }
      > .gsp-search-div-container {
        display: flex;
        flex-direction: column;
        width: 45.5rem;
        gap: 0.6875rem;
        height: 2.25rem;
        > .MuiInput-root {
          height: 1.5rem;
          > .MuiInput-input {
            height: 100%;
            padding: 0;
            font-family: $primaryFontFamily;
            font-weight: 600;
            font-size: $fontSizeL;
            line-height: $lineHeightL;
          }
        }
        > .search-input {
          width: 100%;
          padding: 0;
          background-color: black;

          &::placeholder {
            font-family: $primaryFontFamily;
            font-weight: 400;
            font-size: $fontSizeSM;
            line-height: $lineHeightMD;
            color: $grey70;
          }
        }
      }
    }
  }

  .gsp-component-container {
    background-color: $white100;
    border: $borderline solid $grey94;
    box-shadow: 0 $borderline $atom $simpleShadow;
    border-radius: $tiny;
    width: 100%;
    height: 100%;
    overflow: scroll;
    margin-bottom: $large;
  }
  > .emtpy-result-container {
    margin-top: 4.5rem;
    > .gs-no-results-container {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      > .no-results-icon {
        height: 12.375rem;
        width: 12.375rem;
      }
      > .no-results-header {
        font-family: $primaryFontFamily;
        font-weight: 600;
        font-size: $fontSizeMD;
        line-height: $lineHeightL;
        margin: 0 0 $small 0;
        color: $black;
      }
      > .no-results-subheader {
        font-family: $primaryFontFamily;
        font-weight: 400;
        font-size: $fontSizeSM;
        line-height: $lineHeightMD;
        color: $grey50;
        margin: 0;
      }
    }
  }
}

.gsp-component-wrapper.e-invoice {
  > .emtpy-result-container {
    margin-top: 0;
    background-color: $white100;
    height: 100%;
  }
  .gsp-content-header-container {
    background-color: $white100;
    > .gsp-query-text {
      font-family: $sageFontFamily;
    }
    > .gsp-filter-wrapper {
      font-family: $sageFontFamily!important;
      .MuiOutlinedInput-root {
        .MuiSelect-select {
          font-family: $sageFontFamily!important;   
        }
        font-family: $sageFontFamily!important; 
      }
    }
    > .gsp-search-wrapper {
      > .gsp-search-div-container {
        > .MuiInput-root {
          > .MuiInput-input {
            font-family: $sageFontFamily;
          }
        }
        > .search-input {
          &::placeholder {
            font-family: $sageFontFamily;
          }
        }
      }
    }
  }
  .gsp-component-container {
    margin-bottom: 0;
    > .table-with-infinite-scroll-container {
      > .table-with-infinite-scroll-header {
        background: $eInvoice_grey50;
        border: $borderline solid $eInvoice_grey200;
        > .table-with-infinite-scroll-header-title {
          font-family: $sageFontFamily;
          font-weight: 500;
          font-size: 0.875rem;
          line-height: 1.3125rem;
        }
      }
      > .table-with-infinite-scroll-main-container {
        border: $borderline solid $eInvoice_grey200;
        border-top: none;
        > .MuiTableContainer-root {
          > table.MuiTable-root {
            > thead {
              > tr {
                > th.MuiTableCell-root {
                  height: 3.5rem;
                  border-bottom: $borderline solid $eInvoice_grey200;
                  border-color: #DEE1ED;
                  font-family: $sageFontFamily;
                  font-weight: 500;
                  font-size: 0.75rem;
                  line-height: 1.125rem;
                  > .header-cell-group {
                    // width: 10.063rem;
                    white-space: wrap;
                    text-wrap: wrap;
                    > .header-cell-group-title {
                      > .container > p.default-styles-p {
                        font-family: $sageFontFamily;
                        font-weight: 500;
                        font-size: 0.75rem;
                        line-height: 1.125rem;
                        color: $eInvoice_grey900;
                      }
                    }
                  }
                }
                th:hover {
                  span.MuiCheckbox-root {
                    svg {
                      color: $eInvoice_grey600;
                    }
                  }
                  span.MuiCheckbox-root.Mui-checked {
                    background: none;
                  }
                }
              }
            }
            > tbody {
              > tr {
                border-color: $eInvoice_grey200;
                > th.MuiTableCell-root  {
                  font-family: $sageFontFamily;
                  border-bottom: $borderline solid $eInvoice_grey200;
                  border-top: $borderline solid $eInvoice_grey200;
                  height: 3.5rem;
                  > .infinite-scroll-table-smart-text {
                    font-family: $sageFontFamily;
                    > .container > p.default-styles-p.infinite-scroll-table-data-cell {
                      font-family: $sageFontFamily;
                      font-weight: 400;
                      font-size: 0.875rem;
                      line-height: 1.3125rem;
                    }
                  }
                  
                  span.unread.MuiTypography-root {
                    font-weight: 500;
                  }
                }
                td {
                  .empty-title {
                    font-family: $sageFontFamily;
                    font-weight: 500;
                    font-size: 1.5rem;
                    line-height: 2rem;
                  }
                  .empty-caption {
                    font-family: $sageFontFamily;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 1.5rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
