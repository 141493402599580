@use "../../../../../../css/index" as *;

@mixin audit-log-subtext {
  color: #424242;
  font-family: $sageFontFamily;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.audit-log-container {
  display: flex;
  padding: 1rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  align-self: stretch;
  border-radius: 0.5rem;
  background: #eee;
  margin-bottom: 1.5rem;
  .audit-log-data {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    .audit-log-message-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .audit-log-message {
        color: #212121;
        font-family: $sageFontFamily;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
      .audit-log-timestamp {
        color: #757575;
        text-align: center;
        font-family: $sageFontFamily;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
    .audit-log-reason,
    .audit-log-note {
      @include audit-log-subtext;
    }
  }
}
