@use "../../../css/index" as *;

.toggleChipRoot {
  height: 2.5rem;
  font-family: $primaryFontFamily;
  font-weight: 400;
  font-size: $fontSizeSM;
  line-height: $lineHeightMD;
  background-color: $white100;
  box-sizing: border-box;
  border: $borderline solid $grey90;
  border-radius: $small;
  color: $grey10;
  text-transform: capitalize;
  &:hover {
    background-color: #4d4d4d;
    color: $white100;
  }
  &:global(.Mui-selected) {
    background-color: #4d4d4d;
    color: $white100;
    &:hover {
      background-color: #4d4d4d;
    }
  }
}

.checkIconToggleChipRoot {
  height: 2rem;
  font-family: $primaryFontFamily;
  font-weight: 500;
  font-size: $fontSizeSM;
  line-height: $lineHeightMD;
  background-color: white;
  box-sizing: border-box;
  border: $borderline solid #79747e;
  border-radius: $small;
  color: #1d192b;
  text-transform: capitalize;
  gap: $small;
  &:hover {
    background: rgba(73, 69, 79, 0.08);
    color: #1d192b;
  }
  &:global(.Mui-selected) {
    background-color: #ddecff;
    color: #1d192b;
    border: none;
    &:hover {
      background: rgba(29, 25, 43, 0.08);
    }
  }
  :global(.MuiSvgIcon-root) {
    height: 1.125rem;
    width: 1.125rem;
  }
}
