@use "../../../css/index" as *;

p.subheader.caption.v2 {
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
  color: $greyText !important;

  span.subheader-value,
  .more-contacts-trigger {
    line-height: 1.25rem;
    display: flex;
    align-items: center;
    color: $greyText;
    height: $large;
    padding: 0 $small 0 $small;
  }
  .more-contacts-trigger.active {
    background: #e3f2fd;
    color: #0d47a1;
    border: 0.0313rem solid #0d47a1;
    border-radius: 0.25rem;
    padding: 0 $small 0 $small;
  }
  .contact-popup-trigger {
    padding: 0 $small 0 $small;
    font-style: normal;
    font-weight: 400;
    font-size: 0.6875rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
    text-align: center;
    color: $greyText;
    height: $large;
    border: 0.5px solid $greyText;
    border-radius: 0.25rem;
    margin-right: 0.5rem;
  }
  .contact-popup-trigger.active {
    background: #e3f2fd;
    color: #0d47a1;
    border: 0.0313rem solid #0d47a1;
    border-radius: 0.25rem;
  }
}
