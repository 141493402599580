@import "../../../../../css/index.scss";

//Compnoent specific CSS for copilot
.copilot-wrapper {
  .copilot-accordion-wrapper {
    > .copilot-header {
      position: sticky;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: $base;
      background: $backgroundCopilotHeader;
      border: 0.5px solid $copilotBorder;
      border-radius: $small;
      color: #1e40af;
      margin: $base $base 0 0;
      padding: $base;
      font-weight: 700;
      height: 3.5rem;
      > .copilot-header-right,
      > .copilot-header-left {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
      > .copilot-header-left {
        gap: $small;
      }
      > .copilot-header-right {
        gap: $small;
        > .collapsed-icon-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: $small;
          color: $primary;
        }
      }
    }
  }

  //Expanded state CSS
  .Mui-expanded {
    background-color: $grey98;
    .MuiAccordionSummary-root {
      padding-right: 0;
      min-height: 0;
      padding-left: 0;
      background-color: $grey98;
      .MuiAccordionSummary-content {
        margin-top: 0;
        margin-bottom: 0;
        min-height: 0;
        background-color: $grey98;
        .copilot-accordion-wrapper {
          width: 100%;
          .copilot-header {
            display: flex;
            justify-content: space-between;
            border-radius: $base $base 0 0;
            width: 100%;
            .copilot-header-right {
              padding-right: $small;
            }
          }
        }
      }
    }
    .MuiCollapse-root {
      background-color: $grey98;
      .MuiAccordionDetails-root {
        background-color: $grey98;
        padding: 0;
        .copilot-content {
          border: 0.5px solid $copilotBorder;
          border-radius: 0 0 $base $base;
          .na-wrapper-card.activity-create-card {
            margin-top: 0;
            border-radius: 0 0 $base $base;
            .na-header {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }
  }

  //Collapsed state CSS for Accordion
  .MuiAccordion-root {
    box-shadow: none;
    &::before {
      background-color: none;
      opacity: 0;
    }
    .MuiAccordionSummary-root {
      padding-right: 0;
      padding-left: 0;
      min-height: 0;
      background-color: $grey98;
      .MuiAccordionSummary-content {
        margin-top: 0;
        margin-bottom: 0;
        min-height: 0;
        background-color: $grey100;
      }
    }
    .MuiCollapse-root {
      background-color: $grey98;
      .MuiAccordionDetails-root {
        padding: 0;
        background-color: $grey98;
      }
    }
  }
}
