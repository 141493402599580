@use "../../../css/index" as *;
$activeCrumb: #1A73E8;
$grey30: #4d4d4d;

.breadcrumbs-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  > :first-child{
    margin-left: 1.5rem;
  }
  span.breadcrumbs-back-navigation {
    cursor: pointer;
    svg {
      fill: $grey30;
      height: 0.6875rem;
    }
  }

  nav.breadcrumbs-nav-container {
    ol.breadcrumbs-list-container {
      margin: 0rem;
      padding: 0rem;
      list-style: none;
      display: flex;
      flex-direction: row;

      li.breadcrumbs-list-item {
        cursor: pointer;
        span.crumb {
          font-weight: 500;
          font-size: 0.875rem;
          line-height: 1.25rem;
          color: $grey30;
          text-transform: capitalize;
        }
        span.crumb:hover {
          text-decoration: underline;
        }

        span.breadcrumb-item-separator {
          margin-right: 0.75rem;
          margin-left: 0.75rem;
        }
      }

      li.breadcrumbs-list-item:last-child {
        cursor: default;
        span.crumb {
          font-weight: 600;
          color: $activeCrumb;
        }
        span.crumb:hover {
          text-decoration: none;
          pointer-events: none;
        }
      }
      li.settings-index-crumb.breadcrumbs-list-item:last-child {
        span.crumb {
          text-transform: none;
        }
      }
    }
  }
}

.breadcrumbs-collapse-list {
  .MuiPaper-root {
    margin-top: 0.625rem;
    box-shadow: 0 0.5rem 0.75rem rgb(0 0 0 / 10%), 0 0.25rem 1.25rem rgb(0 0 0 / 10%);
    ul {
      padding: 0rem;
      li {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1rem;
        display: flex;
        align-items: center;
        color: $grey30;
        text-transform: capitalize;
      }
      li:hover {
        background: $grey98;
      }
    }
  }
}
