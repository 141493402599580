@import "../../../../../../../css/index.scss";

.details-wrapper.v2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  padding: 0.625rem $semi 0.625rem $semi;
  gap: 0.5rem;

  min-height: 4.75rem;
  background: $auditLogBg;
  border-radius: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  > .header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
    > .left {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .container:first-child {
        margin-bottom: 0.5rem;
      }

      .creator {
        @include caption;
        overflow: hidden;
        font-style: normal;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.25rem;
        display: flex;
        align-items: center;
        color: $greyText;
      }
      > .time-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      > .time-wrapper > svg {
        display: flex;
        height: 1.25rem;
        width: 1.25rem;
      }
      .message {
        @include caption;
        font-weight: 400;
        color: $grey40;
        flex-basis: 70%;
        padding: 0rem;
        margin-left: 0.2rem;
      }
    }

    > .right {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      gap: $base;
      > .date {
        @include caption;
        color: $grey40;
        text-align: end;
        font-weight: 400;
      }
    }
  }
  > .children-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    p {
      margin: 0rem;
      font-style: normal;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1rem;
      display: flex;
      align-items: center;
      color: $greyText;
    }
  }
}
