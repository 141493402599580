@use "../../../css/index" as *;

.signature-settings.container {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  height: 100%;
  justify-content: space-between;
  > .hyperlink-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: all 0.35s ease-in;
  }
  > .hyperlink-modal-container.is-visible {
    visibility: visible;
    opacity: 1;
    .modal-dialog {
      position: relative;
      width: 50%;
      max-width: 50%;
      background: $white100;
      overflow: auto;
      cursor: default;
      border: 0.063rem solid $backgroundAW;
      box-shadow: 0rem 0.063rem 0.125rem rgba(0, 0, 0, 0.15);
      border-radius: 0.125rem;
    }
    .modal-dialog > * {
      padding: 1rem;
    }
    .modal-header {
      border-radius: 0.3125rem 0.3125rem 0 0;
      background-color: $white100;
      color: $grey40;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      span.modal-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      span.close-button {
        display: flex;
        flex-direction: row;
      }
    }
    .modal-content {
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: $link;
      p {
        overflow: auto;
        height: inherit;
        word-wrap: break-word;
      }
    }
    .modal-footer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      > :first-child {
        margin-right: 0.5rem;
      }
    }
  }
  > .hyperlink-edit-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: all 0.35s ease-in;
  }
  > .hyperlink-edit-modal-container.is-visible {
    visibility: visible;
    opacity: 1;
    .modal-dialog {
      position: relative;
      width: 50%;
      max-width: 50%;
      background: $white100;
      overflow: auto;
      cursor: default;
      border: 0.063rem solid $backgroundAW;
      box-shadow: 0rem 0.063rem 0.125rem rgba(0, 0, 0, 0.15);
      border-radius: 0.125rem;
    }
    .modal-dialog > * {
      padding: 1rem;
    }
    .modal-header {
      border-radius: 0.3125rem 0.3125rem 0 0;
      background-color: $white100;
      color: $grey40;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      span.modal-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      span.close-button {
        display: flex;
        flex-direction: row;
      }
    }
    .modal-content {
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
      padding-top: 0rem;
      padding-bottom: 0rem;

      .modal-form {
        display: flex;
        flex-direction: column;

        label {
          font-style: normal;
          font-weight: 600;
          font-size: 0.875rem;
          line-height: 1.25rem;
          display: flex;
          align-items: flex-end;
          color: $grey40;
        }
        input {
          margin-bottom: 1rem;
          min-height: 2.5rem;
          width: 100%;
          background-color: $grey98;
          border: 0.0625rem solid transparent;
          border-radius: 0.125rem;
          max-height: 4.875rem;
          display: flex;
          color: $primary;
          padding: 0.125rem 0.625rem 0.125rem 0.25rem;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.25rem;
        }
        input:last-child {
          color: $link;
        }
        input:focus,
        input:focus-within,
        input:focus-visible {
          border: $borderline solid $grey70;
          background-color: $grey93;
          outline: none;
        }
      }
    }
    .modal-footer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      > :first-child {
        margin-right: 0.5rem;
      }
    }
  }
  .signature-settings-body {
    .signature-settings-message-text-container {
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: left;
      background: #ffffff;
      border: 0.031rem solid #f7f7fb;
      box-shadow: 0rem 0.063rem 0.125rem rgba(0, 0, 0, 0.15);
      border-radius: 0.5rem;
      .awsome-text-container {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;

        .emoji {
          font-size: 2.033rem;
          margin-right: 1rem;
        }
      }
      .help-text-header {
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 1.15rem;
        display: flex;
        align-items: center;
        color: $grey30;
        margin-bottom: 1rem;
      }
      .help-text-body {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        display: flex;
        align-items: center;
        color: $grey30;
      }
    }
    .signature-container {
      padding: 1.5rem;
      background: #ffffff;
      border: 0.031rem solid #f7f7fb;
      box-shadow: 0rem 0.063rem 0.125rem rgba(0, 0, 0, 0.15);
      border-radius: 0.5rem;
      margin-top: 0.5rem;
      max-height: 34.625rem;
      .signature-text-editor {
        height: 100%;
        position: relative;
        width: 39.375rem;
        .jodit-wrapper {
          -ms-overflow-style: none;
          scrollbar-width: none;
          display: flex;
          flex-direction: column;
          gap: $small;
          border-radius: $atom;
          border: $borderline solid transparent;
          position: relative;
          &::-webkit-scrollbar {
            display: none;
            align-items: center;
            height: 2rem;
          }
          input.attachment-upload-input {
            display: none;
          }
          > .bin-button-wrapper {
            position: absolute;
            bottom: 2.4%;
            left: 0;
            z-index: 9;
            align-items: center;
            justify-content: center;
            display: flex;
            height: 2rem;
            cursor: pointer;
            width: 2rem;
            & :hover {
              background: $toolbarBtnActive;
              border-radius: 0.25rem;
            }
          }
          .bin-button-wrapper:hover {
            background: $toolbarBtnActive;
            border-radius: 0.25rem;
          }
          > .attachment-button-wrapper {
            position: absolute;
            bottom: 2.4%;
            left: 6%;
            z-index: 9;
            align-items: center;
            justify-content: center;
            display: flex;
            height: 2rem;
            cursor: pointer;
            width: 2rem;
          }
          .attachment-button-wrapper:hover {
            background: $toolbarBtnActive;
            border-radius: 0.25rem;
          }
          > .fa-close {
            position: fixed;
            bottom: 0;
          }
          > .rdw-custom-wrapper {
            position: absolute;
            bottom: 2.4%;
            left: 0%;
            z-index: 9;
            align-items: center;
            display: flex;
            height: 2rem;
            .rdw-option-wrapper.rdw-custom-option-wrapper {
              align-items: center;
              border-radius: 0.125rem;
              display: flex;
              height: 2rem;
              justify-content: center;
              margin: 0;
              width: 2rem;
              input {
                display: none;
              }
              .rdw-custom-option-file,
              .rdw-custom-option-file-selected {
                display: flex;
                height: 2rem;
                width: 2rem;
                align-items: center;
                justify-content: center;
                border-radius: 0.25rem;
              }
              .rdw-custom-option-file-selected {
                > svg {
                  width: $semi;
                  height: $semi;
                  path {
                    &:nth-child(2) {
                      fill: $primary;
                    }
                  }
                }
              }
              .rdw-custom-option-file:hover {
                background-color: $toolbarBtnHover;
              }
            }
          }
          > .jodit-toolbar-toggle {
            position: absolute;
            bottom: 2.4%;
            left: 12%;
            z-index: 9;
            align-items: center;
            justify-content: center;
            display: flex;
            height: 2rem;
            cursor: pointer;
            width: 2rem;
          }
          .jodit-toolbar-toggle.active {
            background: $toolbarBtnActive;
            border-radius: 0.25rem;
          }
          .jodit-toolbar-toggle:hover {
            background: $toolbarBtnHover;
            border-radius: 0.25rem;
          }
          > .jodit-react-container {
            height: 100%;
            .jodit-container {
              background-color: $white100;
              display: flex;
              flex-direction: column;
              border: none;
              min-height: 18rem !important;
              .jodit-toolbar__box {
                background-color: $white100;
                width: 41.5rem !important;
                margin: $medium 0 $small 0;
                height: 2.5rem;
                border: 0.0125rem solid $borderGreyLight;
                padding: 0rem 0.5rem;
                box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
                visibility: hidden;
                position: absolute;
                bottom: 2.8125rem;
                top: unset;
                z-index: 9;
                .jodit-toolbar-editor-collection {
                  background: none;

                  .jodit-ui-group {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    .jodit-toolbar-button {
                      align-items: center;
                      display: flex;
                      height: 2rem;
                      justify-content: center;
                      margin: 0;
                      width: 2rem;
                      margin-right: 0.5rem;
                      .jodit-toolbar-button__button {
                        .jodit-toolbar-button__icon {
                          .jodit-icon {
                            height: 1.25rem;
                            width: 1.25rem;
                            fill: $toolbarIconFill;
                            stroke: none;
                          }
                        }
                      }
                    }
                    .jodit-toolbar-button_with-trigger_true:hover:not([disabled]) {
                      border: 0.0625rem solid $white100;
                    }
                    .jodit-toolbar-button__trigger:hover:not([disabled]) {
                      background-color: $white100;
                    }
                    .jodit-ui-separator {
                      align-items: center;
                      background-color: $grey94;
                      display: flex;
                      height: 1rem;
                      margin: 0;
                      width: 0.0625rem;
                    }
                    .jodit-toolbar-button_size_large {
                      height: 2.5rem;
                      min-width: fit-content;
                      button {
                        height: 1.75rem;
                        min-width: 1.75rem;
                        padding: 10%;
                      }
                      button:hover {
                        background-color: $toolbarBtnHover;
                        svg {
                          path {
                            fill: $selectionDark;
                          }
                        }
                      }
                      button:active,
                      button[aria-pressed="true"] {
                        background-color: $toolbarBtnActive;
                        svg {
                          path {
                            fill: $selectionDark;
                          }
                        }
                      }
                    }
                  }
                }
              }
              .jodit-toolbar__box.visible {
                visibility: visible;
              }
              .jodit-workplace {
                border: $borderline solid transparent;
                border-radius: $tiny;
                background-color: $grey98;
                height: 100% !important;
                &:focus-within {
                  border: $borderline solid $grey70;
                  background-color: $grey93;
                }
                .na-body-editor {
                  box-sizing: border-box;
                  min-height: 14.375rem !important;
                  padding: 0.625rem $medium;
                  max-height: 14.375rem;
                  overflow-y: auto;
                  color: $greyText;
                  p {
                    margin-top: 0;
                    margin-bottom: 0;
                  }
                  td,
                  tr {
                    border: none;
                  }
                  // set the pre tag width and text wrap to prevent blockquote content overflow!
                  pre {
                    white-space: pre-wrap; /* Since CSS 2.1 */
                    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
                    white-space: -pre-wrap; /* Opera 4-6 */
                    white-space: -o-pre-wrap; /* Opera 7 */
                    word-wrap: break-word;
                    width: 70vw;
                  }
                }
              }
            }
          }
        }

        .jodit.jodit-popup-container.jodit-box {
          .jodit-popup.jodit-popup_strategy_leftbottom,
          .jodit-popup.jodit-popup_strategy_lefttop {
            .jodit-autocomplete {
              .jodit-autocomplete-item_active_true,
              .jodit-autocomplete-item_active_true:hover {
                background-color: $grey94;
                color: rgba(0, 0, 0, 0.87);
              }
            }
            .jodit-popup__content {
              .jodit-ui-form {
                .jodit-ui-block.jodit-ui-block_align_left.jodit-ui-block_size_middle:nth-child(3),
                .jodit-ui-checkbox.jodit-ui-checkbox_checked_false {
                  display: none;
                }

                .jodit-ui-block.jodit-ui-block_align_full {
                  display: flex;
                  align-items: center;
                  justify-content: space-evenly;
                  grid-gap: 0.625rem;
                  button {
                    width: 100%;
                  }

                  .jodit-ui-button_variant_default {
                    background: $grey98;
                    border: 0.063rem solid $grey90;
                    border-radius: 0.125rem;
                    font-weight: 600;
                    font-size: 0.875rem;
                    line-height: 1.5rem;
                    color: $primary;
                  }
                  .jodit-ui-button_variant_primary {
                    background: $darkBlue;
                    border-radius: 0.125rem;
                    font-weight: 600;
                    font-size: 0.875rem;
                    line-height: 1.5rem;
                    color: $white100;
                  }
                }
              }
            }
          }
        }

        .save-button-container {
          position: absolute;
          bottom: 0rem;
          right: 0rem;
          display: flex;
          gap: 8px;
        }
      }

      .signature-text {
        .signature-header {
          font-weight: 600;
          font-size: 1.125rem;
          line-height: 1.5rem;
          color: #4d4d4d;
        }
        .signature-body-container {
          display: flex;
          flex-direction: row;
          padding-top: 1rem;
          justify-content: space-between;
          max-height: 480px;
          gap: 1rem;
          .signature-body {
            font-style: normal;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.25rem;
            color: #4d4d4d;
            max-width: 37.875rem;
            overflow-y: scroll;
            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
          .signature-controls {
            display: flex;
            flex-direction: row;
            margin-top: auto;
            gap: 0.5rem;
          }
        }
      }
    }
  }
  .signature-settings-help-text-container {
    display: flex;
    flex-direction: row;
    padding: 0.75rem 0.5rem;
    gap: 0.5rem;
    height: 3rem;
    background: #ffffff;
    box-shadow: 0rem 0.063rem 0.125rem rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem 0.25rem 0rem 0rem;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    span {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
      display: flex;
      align-items: center;
      color: $grey60;
    }
  }
}

.modal-signature-settings {
  top: -50%;
  .MuiPaper-root.MuiPaper-elevation.MuiDialog-paper {
    width: 33rem;
    .MuiDialogContent-root {
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1rem;
        color: #4d4d4d;
      }
    }
    .MuiDialogActions-root {
      justify-content: flex-end;
    }
  }
}
