@use "../../../css/index" as *;

@keyframes expand {
  from {
    width: 0;
  }
  to {
    width: 19.5rem;
  }
}

.flyout-wrapper {
  z-index: 1;
  position: absolute;
  right: 0;
  top: 0;
  height: 100vh;
  width: 0;
  overflow: hidden;
  background: $white100;
  box-shadow: 0 $small $medium rgba(0, 0, 0, 0.1), 0 $tiny $semi rgba(0, 0, 0, 0.1);
}

.flyout-expand {
  animation: expand 200ms ease-in-out forwards;
}

.flyout-content {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  padding: $large;
}

.flyout-close {
  position: absolute;
  right: $base;
  top: $base;
  border: none;
  background: white;
  cursor: pointer;
  padding: 0;
  > svg {
    height: 0.625rem;
    width: 0.625rem;
    fill: $grey50;
  }
}
