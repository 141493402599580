@use "../../../css/index" as *;

.settings-wrapper {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: 3.438rem auto;

  > .head {
    border-bottom: $borderline solid $grey94;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 $large;
    > .navbar-wrapper {
      > .navbar-back {
        > .navbar-navlink-back {
          margin-right: 0;
        }
      }
    }
  }
  > .body {
    width: 100%;
    height: 100%;
    padding: $base $large $large $large;
  }
}

.settings-navlink {
  width: auto;
  text-decoration: none;
  height: 100%;
  margin-right: $large;
}

.settings-userPermissions-back-navlink {
  margin-left: auto;
}

.settings-back-btn-wrapper {
  display: flex;
  flex-direction: row;
  gap: 0.625rem;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: auto;

  > svg {
    fill: $grey50;
  }
  > span {
    @include body2;
    color: $grey50;
  }
}

.userPermission,
.workspacePermission {
  &-formatted-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $base;
    p.body1 {
      color: $black;
    }
    .remove-btn p {
      color: $red;
    }
    .action-btn-grp {
      display: flex;
      flex-direction: row;
      justify-content: left;
      gap: $small;
    }
  }
  &-name-col {
    .icon-wrapper {
      height: $larger;
      width: $larger;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $grey90;
      border-radius: 50%;
      flex-shrink: 0;
      svg {
        fill: $grey70;
        width: $medium;
        height: $base;
      }
    }
    p {
      color: $black;
    }
  }
  &-permissions-col {
    width: 8rem;
    .MuiAutocomplete-root {
      &.Mui-focused {
        border: $borderline solid transparent;
      }
      > .MuiFormControl-root {
        > .MuiInput-root {
          height: $large;
          background-color: transparent;
          > .MuiAutocomplete-input {
            text-indent: 0;
            color: $black;
            padding-right: 1.75rem !important;
          }
        }
      }
      & + .MuiAutocomplete-popper {
        > .MuiPaper-root {
          > .MuiAutocomplete-listbox {
            > .MuiAutocomplete-option {
              color: $primary;
              &[aria-selected="true"] {
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }
}

.workspacePermission {
  &-permissions-col {
    width: 4rem;
  }
}

.template-setting-body {
  display: flex;
  flex-direction: column;
  margin-bottom: $base;
  gap: $base;
  > .custom-dropdown-div {
    display: flex;
    height: 2.5rem;
    align-items: center;
  }
}

.empty-language-msg-container {
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.empty-template-msg-container {
  height: 70%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
