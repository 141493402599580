@use "../../../css/index" as *;

.activity-detail-wrapper-grid {
  width: 100%;
  height: 100%;
  display: grid;
  align-items: stretch;
  grid-template-columns: 1fr 4rem;
  > .activity-detail-left {
    > .body {
      position: relative;
      display: grid;
      flex-grow: 1;
      background: $white100;
      border-radius: $tiny $tiny 0 0;
      border: $borderline solid $grey94;
      box-shadow: 0 $borderline $atom rgba(0, 0, 0, 0.15);
      height: calc(100%);
      > .left {
        border-right: $borderline solid $grey94;
      }
      > .right {
        height: 100%;
      }
    }
  }

  // Flyout styles
  .MuiDrawer-paper {
    padding-right: 0;
  }
  .activity-flyout {
    > *:not(.os-host) {
      padding-right: $large;
    }
    .header {
      color: $primary;
      margin-bottom: $small;
    }
    .subheader {
      color: $grey40;
      margin-bottom: $base;
    }
    .sbIcon-wrapper {
      width: auto;
      .sbIcon-input {
        margin: 0;
      }
    }
    label {
      @include caption-med;
      display: inline-block;
      color: $primary;
      margin-bottom: $tiny;
    }
    label + .ta-wrapper .ta-input {
      margin-top: 0;
      margin-bottom: $base;
    }
    .btn-grp {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      margin-top: $small;
    }
    .recap-header {
      margin-top: 2.25rem;
      color: $grey60;
    }
    > .os-host {
      padding-right: $small;
      margin-right: $base;
    }
    ul.recap-list {
      display: flex;
      flex-direction: column;
      list-style: none;
      padding: 0;
      max-height: calc(100vh - 25.9375rem);
      li.recap-item {
        display: flex;
        flex-direction: column;
        .head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: $tiny;
          .caption {
            color: $grey60;
          }
        }
      }
      li.recap-item:not(:last-child) {
        margin-bottom: $large;
      }
    }
  }
}
