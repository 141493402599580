@import "../../../../../../../../css/index";

.boarding-completed {
  display: grid;
  grid-template-columns: 1.75rem auto;
  padding: $large $base;

  &-icon {
    display: flex;
    align-items: center;
    width: 1.5rem;
    margin: auto auto auto 0;
  }
  &-body {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    &-header {
      @include h3;
      color: $grey30;
    }
    &-text {
      @include body1;
      font-weight: 400;
      font-size: 0.75rem;
      width: 100%;
      padding-right: $base;
      > .highlight {
        @include body4;
        color: $grey30;
      }
    }
  }
}

.receiver-list {
  color: $grey40;
  @include caption-med;
}
