@use "../../../css/index.scss" as *;

.gs-main-content-container {
  overflow: hidden;
  width: 100%;
  height: 99%;

  > .gs-no-results-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > .no-results-icon {
      height: "12.375rem";
      width: "12.375rem";
    }
    > .no-results-header {
      font-family: $primaryFontFamily;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5rem;
      margin: 0 0 0.6rem 0;
      color: $black;
    }
    > .no-results-subheader {
      font-family: $primaryFontFamily;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: $grey50;
      margin: 0;
    }
  }

  > .gs-search-loading-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > .gs-search-loading-icon {
      margin-bottom: $base;
      position: relative;
      height: 9.375rem;
      width: 9.375rem;
    }
    > .gs-search-loading-header {
      @include h4;
      margin-bottom: $small;
      color: $black;
    }
    > .gs-search-loading-subheader {
      @include body1;
      color: $grey50;
    }
  }

  > .gs-suggestion-all-category {
    width: 100%;
    height: 100%;
    overflow-y: overlay;
    overflow-x: hidden;
    padding: 0.5rem 0;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }

  > .gs-suggestion-all-category .gs-suggestion-listItem-container {
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-color: $white100;
    transition: background-color 1s linear;

    &:hover {
      background-color: $grey98;
    }
  }
  > .gs-suggestion-all-category .gs-suggestion-listItem-container.activity-streams {
    height: 100%;
  }
  > .gs-suggestion-all-category .gs-suggestion-listItem-container-active {
    background-color: $grey95;
    &:hover {
      background-color: $grey95;
    }
  }

  /*
  * Recent Search Suggestions
  */

  > .gs-recent-suggestions-container {
    width: 100%;
    height: 100%;
    overflow-y: overlay;
    overflow-x: hidden;
    padding: $small 0rem;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
}
