@use "../../../css/index" as *;

// * Accordion Nav Styles
.accordion-megamenu-nav-overrides.MuiAccordion-root {
  background: transparent;
  box-shadow: none;

  .MuiAccordionSummary-root {
    flex-direction: row-reverse;
    padding: $medium 0;
    align-items: center;
    border-radius: $tiny;
    margin-bottom: $small;
    background: transparent;

    p {
      @include body2;
      font-size: $fontSizeXS;
      color: $grey30;
    }

    .icon {
      margin: $borderline $small 0 $small;
    }

    svg {
      fill: $grey30;
    }

    .MuiAccordionSummary-content {
      margin: 0;
      background: transparent;
    }

    .MuiAccordionSummary-expandIconWrapper {
      width: $larger;
      align-items: center;
      justify-content: center;
      color: $grey30;
      background: transparent;
    }

    &:hover {
      p {
        color: $selectionDark;
      }
      svg {
        fill: $selectionDark;
      }
      .MuiAccordionSummary-expandIconWrapper {
        color: $selectionDark;
      }
    }
  }

  &::before {
    background-color: transparent;
  }

  &:hover {
    .MuiAccordionSummary-root {
      background-color: $grey98;
    }
  }

  .MuiAccordionDetails-root {
    display: flex;
    flex-direction: column;
    gap: $tiny;
    padding: 0;
    background: $white100;
    > a {
      text-decoration: none !important;
      margin-left: $larger;
      margin-bottom: $atom;
      &.active {
        background-color: $grey95;
        border-radius: $tiny;
        .count-wrapper {
          display: flex;
        }
      }
    }
  }
}

.accordion-megamenu-nav-overrides.MuiAccordion-root.Mui-expanded {
  margin: 0;
  .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
    min-height: $xlarge;
    background-color: $grey95;
    border-left: solid $tiny $grey30;
    p {
      font-size: $fontSizeSM;
      font-weight: 600;
    }
  }
}

// * Accordion Nav Item Styles
.accordion-megamenu-nav-overrides {
  .accordion-megamenu-nav-item {
    display: flex;
    align-items: center;
    gap: $medium;
    cursor: pointer;
    padding: $tiny;

    p.body2 {
      @include body2;
      display: block;
      color: $grey30;
      white-space: nowrap;
      font-size: $fontSizeXS;
    }

    .count-wrapper {
      display: flex;
      justify-content: center;
      width: 3rem;
      margin-left: auto;
    }

    .count {
      @include body2;
      font-size: $fontSizeXS;
      color: $grey30;
    }

    &:hover {
      border-radius: $tiny;
      background-color: $grey98;
      .icon-wrapper {
        &-text {
          .icon {
            background-color: $white100;
            color: $primary;
          }
          &.active .icon {
            background-color: $darkBlue;
            color: $white100;
          }
        }

        &-img {
          background-color: $white100;
          .icon svg path {
            fill: $primary;
          }

          &.active {
            background-color: $darkBlue;
            .icon svg path {
              fill: $white100;
            }
          }
        }
      }

      p.body2 {
        color: $selectionDark;
      }
      .count {
        color: $selectionDark;
      }
    }
  }
}
