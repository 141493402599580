@use "../../../../css/index" as *;
.export-progress-modal {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    .primary-text,
    .secondary-text {
      margin: 0rem;
      color: #1a1a1a;
      text-align: center;
      font-family: $sageFontFamily;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
    .modal-footer-button-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
  .modal-title {
    color: #1a1a1a;
    font-family: $sageFontFamily;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
}
