@use "../../../css/index" as *;

.MuiMenuItem-overrides.MuiMenuItem-root.nested-MenuItem {
  @include body1;
  display: flex;
  justify-content: space-between;
  min-height: $xlarge;
  color: $primary;
  background-color: $white100;

  svg {
    font-weight: 500;
  }

  &:hover {
    background-color: $grey96;
    font-weight: 500;
  }
  &:active {
    background-color: $grey93;
  }

  &.Mui-selected {
    font-weight: 500;
  }
}
