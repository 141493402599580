@use "../../../../css/index" as *;

.na-body-editor-wrapper {
  display: flex;
  gap: $small;
  flex-direction: column;
  &:hover {
    background-color: $grey96;
  }
  &:focus-within,
  &:active {
    background-color: $grey93;
  }
  &-disabled {
    background-color: $grey98;
    color: $grey70;
    gap: 0;
    cursor: not-allowed;
  }
}

.na-body-editor-toolbar {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: $small;
  background-color: $white100;
  &-disabled {
    display: none;
  }
  > .rdw-inline-wrapper,
  .rdw-history-wrapper,
  .rdw-custom-wrapper {
    display: flex;
    align-items: center;
    height: $larger;
    > .rdw-option-wrapper {
      display: flex;
      margin: 0;
      height: $larger;
      width: $larger;
      align-items: center;
      justify-content: center;
      > img {
        width: $semi;
      }
      > .na-body-message-attachment-input {
        display: none;
      }
      &.rdw-custom-option-wrapper {
        display: flex;
        height: $larger;
        width: $larger;
        border-radius: $atom;
        margin: 0;
        align-items: center;
        justify-content: center;
        > .rdw-custom-option-file {
          height: $semi;
          width: 1.1875rem;
          > svg {
            width: $semi;
            height: $semi;
          }
          &-selected {
            height: $semi;
            width: 1.1875rem;
            > svg {
              width: $semi;
              height: $semi;
              path {
                &:nth-child(2) {
                  fill: $primary;
                }
              }
            }
          }
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  > .rdw-list-wrapper {
    display: flex;
    align-items: center;
    width: $borderline;
    height: $base;
    background-color: $grey94;
    margin: $small 0.625rem;
  }
}

.na-body-wrapper.v2 {
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  gap: $small;
  border-radius: $atom;
  border: $borderline solid transparent;
  position: relative;
  &::-webkit-scrollbar {
    align-items: center;
    display: flex;
    height: 2rem;
  }
  > .na-body-attachments {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $small;
    width: 31.5rem;
    position: absolute;
    bottom: 4rem;
  }
  > .fa-close {
    position: fixed;
    bottom: 0;
  }
  > .rdw-custom-wrapper {
    position: absolute;
    bottom: 2.4%;
    left: 0%;
    z-index: 9;
    align-items: center;
    display: flex;
    height: 2rem;
    .rdw-option-wrapper.rdw-custom-option-wrapper {
      align-items: center;
      border-radius: 0.125rem;
      display: flex;
      height: 2rem;
      justify-content: center;
      margin: 0;
      width: 2rem;
      input {
        display: none;
      }
      .rdw-custom-option-file,
      .rdw-custom-option-file-selected {
        display: flex;
        height: 2rem;
        width: 2rem;
        align-items: center;
        justify-content: center;
        border-radius: 0.25rem;
      }
      .rdw-custom-option-file-selected {
        > svg {
          width: $semi;
          height: $semi;
          path {
            &:nth-child(2) {
              fill: $primary;
            }
          }
        }
      }
      .rdw-custom-option-file:hover {
        background-color: $toolbarBtnHover;
      }
    }
  }
  > .email-template-wrapper {
    position: absolute;
    bottom: 2.4%;
    left: 3%;
    z-index: 9;
    > .container,
    .container-selected {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
      border-radius: 0.25rem;
    }
    > .container-selected {
      background-color: rgba(245, 245, 245, 1);
    }
  }
  .email-template-wrapper :hover {
    cursor: pointer;
    background-color: rgba(245, 245, 245, 1);
  }
  > .jodit-toolbar-toggle,
  .jodit-toolbar-toggle-with-template {
    position: absolute;
    bottom: 2.4%;
    left: 3%;
    z-index: 9;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 2rem;
    cursor: pointer;
    width: 2rem;
  }
  > .jodit-toolbar-toggle-with-template {
    left: 6%;
  }
  .jodit-toolbar-toggle.active {
    background: $toolbarBtnActive;
    border-radius: 0.25rem;
  }
  .jodit-toolbar-toggle:hover {
    background: $toolbarBtnHover;
    border-radius: 0.25rem;
  }
  > .jodit-react-container {
    margin-bottom: 3.3rem;
    border-bottom: $borderline solid $grey90;
    .jodit-container {
      background-color: $white100;
      display: flex;
      flex-direction: column;
      border: none;
      .jodit-toolbar__box {
        background-color: $white100;
        width: 41.5rem !important;
        margin: $medium 0 $small 0;
        border: 0.0125rem solid $borderGreyLight;
        padding: 0rem 0.5rem;
        height: 2.75rem;
        box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
        visibility: hidden;
        position: absolute;
        bottom: 2.8125rem;
        top: unset;
        z-index: 9;
        .jodit-toolbar-editor-collection {
          background: none;

          .jodit-ui-group {
            display: flex;
            align-items: center;
            width: 100%;
            .jodit-toolbar-button {
              align-items: center;
              display: flex;
              height: 2rem;
              justify-content: center;
              margin: 0;
              width: 2rem;
              margin-right: 0.5rem;
              .jodit-toolbar-button__button {
                .jodit-toolbar-button__icon {
                  .jodit-icon {
                    height: 1.25rem;
                    width: 1.25rem;
                    fill: $toolbarIconFill;
                    stroke: none;
                  }
                }
              }
            }
            .jodit-toolbar-button_with-trigger_true:hover:not([disabled]) {
              border: 0.0625rem solid $white100;
            }
            .jodit-toolbar-button__trigger:hover:not([disabled]) {
              background-color: $white100;
            }
            .jodit-ui-separator {
              align-items: center;
              background-color: $grey94;
              display: flex;
              height: 1rem;
              margin: 0;
              width: 0.0625rem;
            }
            .jodit-toolbar-button_size_large {
              height: 2.5rem;
              min-width: fit-content;
              button {
                height: 1.75rem;
                min-width: 1.75rem;
                padding: 10%;
              }
              button:hover {
                background-color: $toolbarBtnHover;
                svg {
                  path {
                    fill: $selectionDark;
                  }
                }
              }
              button:active,
              button[aria-pressed="true"] {
                background-color: $toolbarBtnActive;
                svg {
                  path {
                    fill: $selectionDark;
                  }
                }
              }
            }
          }
        }
      }
      .jodit-toolbar__box.visible {
        visibility: visible;
      }
      .jodit-workplace:focus-within ::-webkit-scrollbar-corner {
        background-color: white;
      }
      .jodit-workplace {
        border: $borderline solid transparent;
        background-color: $white100 !important;
        overflow: auto;
        &:focus-within {
          border: none !important;
          background-color: $grey93;
        }
        ::-webkit-scrollbar-corner {
          background-color: white;
        }
        .na-body-editor-card {
          box-sizing: border-box;
          padding: 0.625rem 0rem;
          max-height: 22.5rem;
          overflow: auto;
          color: $greyText;
          p {
            margin-top: 0;
            margin-bottom: 0;
          }
          td,
          tr {
            border: none;
          }
          // set the pre tag width and text wrap to prevent blockquote content overflow!
          pre {
            white-space: pre-wrap; /* Since CSS 2.1 */
            white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
            white-space: -pre-wrap; /* Opera 4-6 */
            white-space: -o-pre-wrap; /* Opera 7 */
            word-wrap: break-word;
            width: 70vw;
          }
        }
      }
    }
  }
  .mention-hoc {
    ul.mentions {
      list-style: none;
      display: flex;
      flex-direction: column;
      padding: 0;
      background-color: $white100;
      color: rgba(0, 0, 0, 0.87);
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5;
      letter-spacing: 0.00938rem;
      overflow: auto;
      border-radius: 0 0 0.125rem 0.125rem;
      box-shadow: 0 0.5rem 0.75rem rgb(0 0 0 / 10%), 0 0.25rem 1.25rem rgb(0 0 0 / 10%);
      max-height: 15.625rem;
      position: absolute;
      top: 1.25rem;
      z-index: 9;

      li.mention {
        display: flex;
        flex-direction: row;
        padding: 0.625rem;
        align-items: center;
        cursor: pointer;

        .user-pic {
          max-width: 2.5rem;
          min-width: 2.5rem;
          height: 2.5rem;
          background-color: $grey96;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          svg {
            fill: $grey60;
          }
        }
        .mention-data {
          display: flex;
          flex-direction: column;
          margin-left: 0.625rem;
          .name {
            overflow-wrap: break-word;
            max-width: 18.75rem;
          }
          .email {
            overflow-wrap: break-word;
            max-width: 18.75rem;
          }
        }
      }
      li.mention:focus,
      li.mention:hover {
        background-color: $grey94;
      }

      li.mention-disabled {
        display: flex;
        flex-direction: row;
        padding: 0.625rem;
        align-items: center;

        .user-pic {
          max-width: 2.5rem;
          min-width: 2.5rem;
          height: 2.5rem;
          background-color: $grey96;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          svg {
            fill: $grey60;
          }
        }
        .mention-data {
          display: flex;
          flex-direction: column;
          margin-left: 0.625rem;
        }
        .mention-data-loading {
          .name {
            padding: 0.25rem 0.5rem;
          }
          .email {
            padding: 0.25rem 0.5rem;
          }
        }
      }
    }
    ::-webkit-scrollbar-button {
      height: 5rem;
    }
    > .jodit-react-container {
      .jodit-container {
        background-color: $white100;
        display: flex;
        flex-direction: column-reverse;
        border: none;
        .jodit-toolbar__box {
          background-color: $white100;
          border: none;
          margin: $medium 0 $small 0;
          height: 2.5rem;
          .jodit-toolbar-editor-collection {
            background: none;

            .jodit-ui-group {
              display: flex;
              align-items: center;
              .jodit-toolbar-button {
                align-items: center;
                display: flex;
                height: 2rem;
                justify-content: center;
                margin: 0;
                width: 2rem;
                .jodit-toolbar-button__button {
                  .jodit-toolbar-button__icon {
                    .jodit-icon {
                      height: 1.25rem;
                      width: 1.25rem;
                      fill: $grey60;
                      stroke: none;
                    }
                  }
                }
              }
              .jodit-ui-separator {
                align-items: center;
                background-color: $grey94;
                display: flex;
                height: 1rem;
                margin: 0;
                width: 0.0625rem;
              }
              .jodit-toolbar-button_size_large {
                height: 2.5rem;
                min-width: fit-content;
                button {
                  height: fit-content;
                  min-width: fit-content;
                  padding: 10%;
                }
              }
            }
          }
        }
        .jodit-workplace {
          border: $borderline solid transparent;
          background-color: $grey98;
          overflow: auto;
          &:focus-within {
            border: $borderline solid $grey70;
            background-color: $grey93;
          }
          .na-body-editor-card {
            box-sizing: border-box;
            padding: 0.625rem $medium;
            overflow-y: hidden;
            p {
              margin-top: 0;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.jodit.jodit-popup-container.jodit-box {
  .jodit-popup.jodit-popup_strategy_leftbottom,
  .jodit-popup.jodit-popup_strategy_lefttop {
    .jodit-autocomplete {
      .jodit-autocomplete-item_active_true,
      .jodit-autocomplete-item_active_true:hover {
        background-color: $grey94;
        color: rgba(0, 0, 0, 0.87);
      }
      .mention {
        display: flex;
        flex-direction: row;
        padding: 0.625rem;
        align-items: center;
        cursor: pointer;

        .user-pic {
          max-width: 2.5rem;
          min-width: 2.5rem;
          height: 2.5rem;
          background-color: $grey96;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          svg {
            fill: $grey60;
          }
        }
        .mention-data {
          display: flex;
          flex-direction: column;
          margin-left: 0.625rem;
          .name {
            overflow-wrap: break-word;
            max-width: 18.75rem;
          }
          .email {
            overflow-wrap: break-word;
            max-width: 18.75rem;
          }
        }
      }
    }
    .jodit-popup__content {
      .jodit-ui-form {
        .jodit-ui-block.jodit-ui-block_align_left.jodit-ui-block_size_middle:nth-child(3),
        .jodit-ui-checkbox.jodit-ui-checkbox_checked_false {
          display: none;
        }

        .jodit-ui-block.jodit-ui-block_align_full {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          grid-gap: 0.625rem;
          button {
            width: 100%;
          }

          .jodit-ui-button_variant_default {
            background: $grey98;
            border: 0.063rem solid $grey90;
            border-radius: 0.125rem;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1.5rem;
            color: $primary;
          }
          .jodit-ui-button_variant_primary {
            background: $darkBlue;
            border-radius: 0.125rem;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1.5rem;
            color: $white100;
          }
        }
      }
    }
  }
}

.jodit-autocomplete {
  .jodit-autocomplete-item_active_true,
  .jodit-autocomplete-item_active_true:hover {
    background-color: $grey94;
    color: rgba(0, 0, 0, 0.87);
  }
  .mention {
    display: flex;
    flex-direction: row;
    padding: 0.625rem;
    align-items: center;
    cursor: pointer;

    .user-pic {
      max-width: 2.5rem;
      min-width: 2.5rem;
      height: 2.5rem;
      background-color: $grey96;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      svg {
        fill: $grey60;
      }
    }
    .mention-data {
      display: flex;
      flex-direction: column;
      margin-left: 0.625rem;
      .name {
        overflow-wrap: break-word;
        max-width: 18.75rem;
      }
      .email {
        overflow-wrap: break-word;
        max-width: 18.75rem;
      }
    }
  }
}

.jodit-emoji__emojis::-webkit-scrollbar-button {
  height: auto;
}
