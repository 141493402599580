@use "../../../css/index" as *;

.ws-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $onboardBackground;

  > .ws-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    border: $borderline solid $onboardBorder;
    box-shadow: 0 $atom 0.1875rem rgba(111, 130, 142, 0.556818);
    border-radius: $atom;
    gap: $xlarge;

    > .ws-loading-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 10rem;
      height: 10rem;

      svg {
        position: absolute;
        animation: rotate 2s linear infinite;

        circle {
          fill: $onboardBackground;
        }
      }
    }
    > .message-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: $tiny;
      > .text {
        @include h2;
        color: $grey30;
        text-align: center;
      }
      > .secondary-text {
        @include body1;
        color: $grey30;
        text-align: center;
      }
    }
  }
}
