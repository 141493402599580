@use "../../../../../css/index" as *;

.action-bar-container {
  height: 3.5rem;
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: $base;
  background: $grey100;
  border-bottom: $borderline solid $grey200;
  > .head-grp {
    display: flex;
    flex-direction: row;
    gap: $small;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    > .left,
    > .right {
      display: flex;
      gap: $small;
      align-items: center;
    }
    > .left {
      flex-wrap: wrap;
      > .company-title {
        @include h4;
        cursor: pointer;
      }
      > .connection-tag {
        display: flex;
        align-items: center;
        border-radius: $tiny;
        @include caption-sm;
        text-transform: capitalize;
        padding: $tiny $small;
      }
    }
    > .right {
      > .activity-flag {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        > .activity-flag-icon {
          width: $base;
          height: $base;
          margin-right: $small;
        }
        > .activity-flag-title {
          font-size: 0.875rem;
          font-weight: 400;
          line-height: $base;
          text-align: center;
        }
      }
      .purple {
        > span {
          color: $purple;
        }
        svg > path {
          fill: $purple;
        }
      }
      .orange {
        > span {
          color: $notStarted;
        }
        svg > path {
          fill: $notStarted;
        }
      }
      .green {
        > span {
          color: $connectedGreen;
        }
        svg > path {
          fill: $connectedGreen;
        }
      }
      > .mark-as-complete {
        .MuiButton-root {
          text-transform: none;
          padding: 0 0.5rem;
          border-radius: 0.5rem;
          font-family: $sageFontFamily;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 500;
          line-height: 1.125rem;
          gap: 0.5rem;
          height: 2rem;
          .MuiButton-startIcon {
            margin: 0;
            > svg {
              width: 0.875rem;
              height: 1rem;
              path {
                fill: #424242;
              }
            }
          }
        }
        .MuiButton-outlined {
          border-color: #4d4d4d;
          color: #424242;
        }
      }
      > .command-center-button {
        display: flex;
        align-items: center;
        cursor: pointer;
        color: $white100;
      }
    }
  }
}
