@use "../../../../../css/index" as *;

$borderBottom: #e5e5e5;

@mixin attachment-active {
  padding: $atom 0.1875rem;
  background: $onboardBorder;
  border: $borderline solid $grey90;
  border-radius: $atom;
}

@mixin body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  .header {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    gap: $base;
    .header-details {
      display: flex;

      .chip {
        height: $semi;
        padding: $atom $tiny;
        width: fit-content;
        margin-left: $small;
      }
      .body2 {
        color: $black;
        span.activity-creator {
          display: inline-flex;
        }
        .activity-variant {
          color: $grey40;
        }
      }
      .tag.attachments {
        height: $semi;
        font-style: normal;
        font-weight: 500;
        font-size: $medium;
        display: flex;
        align-items: center;
        padding: $atom $tiny;
        letter-spacing: $tiny;
        text-transform: uppercase;
        color: $grey60;
        flex: none;
        flex-grow: 0;
        margin: 0 0 0 $tiny;
        cursor: pointer;
      }
      .tag.attachments.active {
        @include attachment-active;
      }
    }
    .header-date {
      @include caption;
      color: $grey40;
      gap: $base;
      align-self: center;
    }
    &-flag {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      &-icon {
        margin: 0 $tiny;
        display: flex;
        & svg > path {
          width: $base;
          height: $base;
        }
      }
      &-title {
        @include body1;
        line-height: $base;
      }
    }
    & .purple {
      color: $purple;
      & svg > path {
        fill: $purple;
      }
    }
    & .green {
      color: $connectedGreen;
      & svg > path {
        fill: $connectedGreen;
      }
    }
    & .orange {
      color: $notStarted;
      & svg > path {
        fill: $notStarted;
      }
    }
  }
  .action-wrapper {
    display: flex;
    flex-direction: row;

    button {
      margin-right: $tiny;
    }
  }
  .subheader-wrapper {
    margin-bottom: $small;
  }

  .subheader {
    color: $grey60;
    margin-top: $tiny;
    margin-right: 4.5rem;
    word-break: break-all;
    white-space: normal;
    cursor: pointer;
  }
  > .subheader-container {
    margin-bottom: $large;
    > .subheader {
      color: $grey60;
      margin-top: $tiny;
      margin-right: 4.5rem;
      word-break: break-all;
      white-space: normal;
    }
  }
  > .content {
    color: $black;
    min-width: 0;
  }
  .invalid-email-body {
    line-height: $semi;
    word-break: break-all;
    .bold {
      font-weight: bold;
    }
    .para {
      padding-bottom: $base;
    }
  }
  > .email-renderer {
    width: 100%;
    height: 100%;
    border: none;
    color: #4d4d4d;
  }

  .email-renderer.full-height {
    max-height: 4.875rem;
    overflow: hidden;
    user-select: none;
  }

  .email-container,
  .invalid-email-body {
    width: 100%;
    height: fit-content;
    @include body1;
    word-break: break-word;
    border: none;
    color: #4d4d4d;

    mark {
      background-color: rgba(243, 137, 35, 0.5);
    }
  }
  .expanded-margin {
    margin-bottom: 0;

    > p {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  > .snooze-feed {
    p {
      line-height: $semi;
      margin-top: 0;
      font-size: 0.875rem;
    }
  }

  > .button-container {
    .retry-email {
      padding: $tiny $small;
    }
  }
  .close-container {
    background-color: $white100;
    display: flex;
    position: absolute;
    top: -$small;
    right: $tiny;
    padding: $tiny;
  }
}

@mixin activity-feed {
  > .left-column {
    > .icon {
      height: 2.279rem;
      width: 2.279rem;
      border-radius: 50%;
      background-color: $white100;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.0313rem solid $primary;
      font-style: normal;
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.5rem;
      color: $black;
    }
  }
  .body {
    @include body;
  }
}

.attachments-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5625rem;
  background: $white100;
  border: $borderline solid $grey90;
  box-sizing: border-box;
  border-radius: $atom;
  margin: $base 0;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  position: relative;

  .list-wrapper {
    max-width: 60%;
    .attachments-container {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      overflow: auto;
      white-space: nowrap;
      row-gap: $tiny;
      column-gap: $medium;
      width: 26.3125rem;
    }
  }
  .attachments-action {
    display: flex;
    justify-content: flex-end;
    justify-items: center;

    .attachments-button-wrapper {
      display: flex;
      justify-content: space-between;
      column-gap: $small;
    }
    .download-all {
      white-space: nowrap;
    }
    .btn.btn-secondary.download-all {
      background: $grey98;
      border: $borderline solid $grey90;
      box-sizing: border-box;
      border-radius: $atom;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 0 $small;
      font-weight: 600;
      font-size: $medium;
      line-height: $base;
      display: flex;
      align-items: center;
      color: $primary;
      height: $large;
      padding: $tiny $small;
    }
  }
}
.activity-feed-item-card {
  background-color: $white100;
  box-shadow: 0rem 0.0625rem 0.125rem rgb(0 0 0 / 15%);
  border-radius: 0.5rem;
  padding: $large $base;
  margin: 0.625rem 0;
}

.activity-feed-item-wrapper.v2 {
  display: flex;
  min-height: 5.5rem;
  margin-bottom: 0.625rem;
  margin-top: 1rem;
  background-color: $white100;
  box-shadow: none;
  border-radius: 0.5rem;
  padding: 0;
  > .body {
    flex: 0 1 auto;
    &:hover {
      cursor: pointer;
    }

    > .feed-item-section {
      padding: $semi $semi;
      width: 100%;

      .e-invoice-actions {
        display: flex;
        flex-direction: row;
        gap: 0.75rem;
        margin-bottom: 1.5rem;
      }
      .e-invoice-line-items {
        table {
          table-layout: fixed;
          width: 90%;
          border-collapse: collapse;
          margin-bottom: 1.5rem;
          thead {
            tr {
              th {
                text-align: left;
                padding: 0.5rem;
                background: #dadada;
                border-bottom: 1px solid #dadada;
              }
              th.numeric {
                text-align: right;
              }
            }
          }
          tbody {
            tr {
              td {
                text-align: left;
                padding: 0.5rem;
                border-bottom: 1px solid #dadada;
              }
              td.numeric {
                text-align: right;
              }
            }
          }
        }
      }
    }

    .feed-body {
      .feed-item-attachments-container {
        border-bottom: 0.0625rem solid $borderBottom;
        margin-bottom: 0.5rem;
        .attachemnts-actions {
          padding-left: 1rem;
          margin-top: 0.625rem;
          margin-bottom: 0.625rem;
          a {
            font-style: normal;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1.25rem;
            color: $babyBlue;
            text-decoration: none;
          }
        }
      }
    }

    > .header-section-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 0.0625rem solid $borderBottom;
      .header-left-section {
        @include activity-feed;

        display: flex;
        flex-direction: row;
        > .left-column {
          padding-right: 1.138rem;
          flex: 0 0 auto;
          &:hover {
            cursor: pointer;
          }
        }
        > .right-column {
          .subheader-wrapper {
            > table.subheader-key-value-table {
              border-collapse: separate;
              margin-top: 0.875rem;
              tr {
                td {
                  padding-right: 1.1875rem;
                  p.subheader.caption {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    display: flex;
                    align-items: center;
                    color: $greyText;

                    span.subheader-value {
                      font-style: normal;
                      font-weight: 400;
                      font-size: 0.875rem;
                      line-height: 1.25rem;
                      display: flex;
                      align-items: center;
                      color: $greyText;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .header-right-section {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .secondary-actions-row {
          display: flex;
          flex-direction: row;
          align-items: center;

          .chip-new,
          .chip-seen,
          .chip-lg {
            height: 1rem;
            border-radius: 0.25rem;
            font-style: normal;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 1rem;
            border: none;
            text-transform: capitalize;
            padding: 0rem 0.25rem;
            .chip-bg {
              border-radius: 0.25rem;
            }
          }
          .emoji-actions {
            button {
              span {
                display: flex;
              }
            }
          }

          .more-actions {
            button {
              svg {
                transform: rotate(90deg);
                height: 3.33px;
              }
            }
          }
          .reply-action,
          .reply-all-action,
          .forward-action {
            .MuiButtonBase-root {
              padding: 0;
            }
            .MuiIconButton-root {
              :hover {
                background-color: #ebf1fa;
                border-radius: 0.25rem;
              }
            }
          }
        }

        .reply-action:hover svg path,
        .reply-all-action:hover svg path,
        .forward-action:hover svg path {
          fill: #1c4da5;
        }

        .timestamp-row {
          margin-top: 1.383rem;
          .header-date {
            font-style: normal;
            font-weight: 500;
            font-size: 0.75rem;
            line-height: 1rem;
            display: flex;
            align-items: center;
            color: $greyText;
          }
        }
        .feed-item-type-row {
          margin-top: 0.625rem;
        }
      }
    }
  }
}
.activity-feed-item-wrapper.loader-skeleton {
  padding: 1rem;
}

.modal-activity-feed-item-render {
  width: 63.6875rem;
  min-height: 35rem;
  transition: min-height 10s;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: $white100;
  border-radius: $tiny;
  display: flex;
  flex-direction: column;
  max-height: 80%;

  .os-host,
  .os-host-overflow {
    overflow: hidden !important;
    max-height: 25rem;
    padding-right: 6rem;
  }

  .modal-close-icon {
    position: absolute;
    right: 0.91375rem;
    top: 1.04rem;
    svg {
      fill: $grey40;
      cursor: pointer;
    }
  }
  .modal-content-activity-feed {
    display: grid;
    grid-template-columns: $xlarge calc(100% - 4rem);
    min-height: 5.5rem;
    gap: $large;
    padding: $large 0;
    @include activity-feed;
    padding: 2.0625rem $xlarge $xlarge;

    .original-body {
      @include body;
      display: block;

      .header {
        display: flex;
        gap: 1rem;
        justify-content: flex-start;
        > .attachments {
          display: flex;
          align-items: center;
          padding: 0.1875rem $tiny;
        }
        > .active {
          @include attachment-active;
        }
      }
    }

    .attachments-wrapper {
      display: flex;
      flex-direction: row;
      padding: 0.5625rem;
      background: $white100;
      border: $borderline solid $grey90;
      box-sizing: border-box;
      border-radius: $atom;
      margin-top: $base 0;
      justify-content: space-between;
      width: 100%;
      position: relative;
      align-items: center;

      .attachments-list {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% - 9.125rem);
        padding: $small 0 0 $small;
        border-right: $borderline solid $grey90;
      }
      .attachments-action {
        padding: $small 0;
        width: 9.125rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        align-content: center;
        justify-items: center;
        height: 100%;
        .attachments-button-wrapper {
          display: flex;
          justify-content: space-between;
        }
        .btn.btn-secondary.download-all {
          background: $grey98;
          border: $borderline solid $grey90;
          box-sizing: border-box;
          border-radius: $atom;
          flex: none;
          order: 1;
          flex-grow: 0;
          margin: 0 $small;
          font-weight: 600;
          font-size: $medium;
          line-height: $base;
          display: flex;
          align-items: center;
          color: $primary;
          height: $large;
          padding: $tiny $small;
        }
      }
    }
  }
}

.magic-link-cta-container {
  margin: 0.5rem 0rem 0.5rem 0rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;

  a.magic-link-cta {
    text-decoration: none;
    margin-right: 0.5rem;
  }

  a.magic-link-cta.disabled {
    background-color: $primary;
    opacity: 0.4;
    cursor: auto;
    pointer-events: none;
  }

  p.magic-link-cta-eta {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.25rem;
    display: flex;
    align-items: center;
    color: $black;
  }
}

.activity-action-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.625rem 0 0 0;

  > .left {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 0.625rem;
    .btn {
      padding-left: $small;
      padding-right: $small;
    }
    > * {
      margin-right: 0.75rem;
      display: flex;
      align-items: center;
    }
  }

  > .right {
    display: flex;
    align-items: center;

    > .automate-time-wrapper {
      display: flex;
      height: $large;
      align-items: center;
      > .time-text-wrapper {
        font-family: $primaryFontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: $medium;
        line-height: $base;
        color: $grey60;
        padding: 0 $small;
      }
    }
  }
  .reply-action .MuiIconButton-root,
  .tooltip-actions .MuiIconButton-root,
  .emoji-actions .MuiIconButton-root {
    svg {
      height: $base;
      width: $base;
    }

    &:hover,
    &:hover svg path {
      fill: $primary !important;
    }
  }

  .tooltip-actions .MuiIconButton-root svg {
    transform: rotate(90deg);
  }
}

.reaction-tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.5rem;
  background-color: $white100;
  box-shadow: 0rem $borderline $atom rgba(0, 0, 0, 0.25);
  border-radius: 0.375rem;
  @include caption-sm;
  cursor: pointer;
  .reaction {
    @include body1;
    margin-right: 0.5rem;
  }
}

.reaction-tag-highlight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.5rem;
  background-color: $white100;
  box-shadow: 0rem $borderline $atom rgba(0, 0, 0, 0.35);
  border-radius: 0.375rem;
  @include caption-sm;
  cursor: pointer;
  .reaction {
    @include body1;
    margin-right: 0.5rem;
  }
  margin-right: 0.5rem;
  color: $primary;
  font-weight: 600;
}

.reaction-tag:hover,
.reaction-tag-highlight:hover {
  box-shadow: 0 $small $small rgba(0, 0, 0, 0.25);
  background-color: $grey96;
}

.emoji-tooltip {
  background: $white100 !important;
  margin: 0 !important;
  padding: 0 !important;
  border-radius: $atom;
  box-shadow: 0 $small $medium rgba(0, 0, 0, 0.1), 0 $tiny $semi rgba(0, 0, 0, 0.1);
  display: flex;
  > .MuiMenuItem-root {
    padding: 0.5rem;
  }
}
