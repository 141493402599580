@use "../../../css/base";
@use "../../../css/spacing";
@use "../../../css/typography";

.hover-wrapper {
  display: flex;
  align-items: flex-start;
  padding: spacing.$tiny spacing.$small;
  background-color: #4d4d4d;
  border-radius: spacing.$tiny;
  color: base.$white100;
  box-shadow: 0 spacing.$borderline spacing.$atom rgb(0 0 0 / 15%);
  font-family: typography.$primaryFontFamily;
  font-weight: 400;
  font-size: typography.$fontSizeXS;
  line-height: typography.$lineHeightSM;
}

.hover-square {
  height: 0.825rem;
  aspect-ratio: 1;
}

.hover-text {
  @include typography.caption;
  text-transform: lowercase;
}

.hover-text::first-line {
  text-transform: capitalize;
}
