@import "../../../../../../../../css/index";

.onboard-activity {
  display: grid;
  grid-template-columns: 2.5rem auto auto;
  padding: $small $base $small $base;

  &-icon {
    height: 1.5rem;
    width: 1.5rem;
    margin: auto $base auto auto;
  }
  &-body {
    &-header {
      @include h3;
      color: $grey30;
    }
    &-text {
      @include caption;
      font-weight: 400;
      margin: 0;
      > .highlight {
        @include body4;
        color: $grey30;
      }
    }
  }
  &-action {
    margin-left: auto;
    gap: $tiny;
    .request-onboard-btn,
    .dismiss-onboard-btn {
      padding: 0.625rem $base;
      border-radius: 0.375rem;
      &:hover {
        background-color: #1a73e8;
        color: $white100;
      }
    }
    .dismiss-onboard-btn {
      color: #1a73e8;
    }
    .request-onboard-btn {
      background-color: #1a73e8;
    }
    .request-onboard-menu {
      padding: 0.625rem $base;
      border-radius: 0.375rem;

      &-details {
        /* Auto layout */
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: $medium;

        // point downwords
        > .expanded {
          transform: rotate(180deg);
          border-radius: $tiny;
          transition: all 0.2s ease;
        }
        // point right
        > .close {
          transform: rotate(90deg);
          transition: all 0.2s ease;
        }
      }
      /* White - 100 */
      background: $white100;
      /* Grey - 90 */
      border: 1px solid $grey90;

      &-title {
        @include body1;
      }
    }
  }
  &-action,
  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
