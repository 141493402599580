@use "../../../../css/index" as *;

.megamenu-userpic-btn {
  height: $xlarge;
  width: $xlarge;
  background-color: $grey90;
  align-items: center;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 0;

  > svg {
    pointer-events: none;
    fill: $grey50;
  }
  > img {
    border-radius: 50%;
    object-fit: cover;
    width: 2.5rem;
    height: 2.5rem;
  }
  &-hoverable {
    &:hover {
      cursor: pointer;
    }
  }
}

.user-icon-default {
  cursor: default;
}
