@use "../../../../css/index" as *;

.invite-flyout-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 5;

  > .header {
    @include h3;
    color: $primary;
    margin-bottom: $large;
  }

  > .input-header {
    @include caption;
    font-weight: 600;
    color: $primary;
  }

  > .sb-wrapper {
    &.bottom {
      padding-bottom: $medium;
    }
    &.top {
      padding-top: $xlarge;
    }

    > .sb-label-container {
      > .sb-label {
        > .sb-label-optional {
          font-weight: 500;
        }
      }
    }
    input {
      color: $black;
    }
  }

  > .invite-btn-grp {
    margin-top: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > .invite-btn {
      padding: $small $medium;

      > .btn-text {
        white-space: nowrap;
      }
    }
  }

  > .add-contact-btn-grp {
    margin-top: $large;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > .invite-btn {
      padding: $small $medium;

      > .btn-text {
        white-space: nowrap;
      }
    }
  }
  > .checkbox-wrapper {
    padding-top: $medium;
    display: flex;
    > .contact-text {
      @include body1;
      height: 100%;
      padding-left: $medium;
      color: $primary;
    }
    > .is-primary-checkbox {
      &[type="checkbox"] {
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: 0;
        background: $white100;
        width: 1.125rem;
        height: 1.125rem;
        border: $atom solid $primary;
        border-radius: $borderline;
        margin: 0;
      }
      &[type="checkbox"]:checked {
        background: $primary;
        border: none;
      }
      &[type="checkbox"]:hover {
        filter: brightness(90%);
        border-color: $primary;
      }

      &[type="checkbox"]:disabled {
        background: #e6e6e6;
        opacity: 0.6;
        pointer-events: none;
      }

      &[type="checkbox"]:after {
        content: "";
        position: relative;
        left: 35%;
        top: 10%;
        width: 35%;
        height: 55%;
        border: solid #fff;
        border-width: 0 $atom $atom 0;
        transform: rotate(45deg);
        display: none;
      }
      &[type="checkbox"]:checked:after {
        display: block;
      }

      &[type="checkbox"]:disabled:after {
        border-color: #7b7b7b;
      }
    }
  }
}
