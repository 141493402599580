@import "./../../css/index.scss";

.redirect-form-loader {
  height: 50vh;
}

.approval-container {
  width: 69rem;
  height: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-top: $large;
  align-items: center;
  justify-content: space-between;
  border-radius: $small;
  > .header {
    height: 3.5rem;
    width: 66rem;
    margin-bottom: 1.375rem;
    background-color: $grey98;
    padding: $base 1.125rem $base 1.125rem;
    border-radius: $atom;
  }
}
