@use "../../../css/index" as *;

.table-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  padding-bottom: $base;

  > .body-table {
    display: flex;
    flex-grow: 1;
    background: $white100;
    border-radius: $fullRadius;
    width: 100%;
    .table-root {
      width: 100%;
    }
  }
}
.main-table-wrapper {
  .table-head > .row {
    &:hover {
      .head-col-last-fixed {
        background-color: $grey98;
      }
    }
  }

  .table-body > .row {
    &:hover {
      .col {
        &-last-fixed {
          background-color: $grey98;
        }
      }
    }
  }
  .table-head > .row > .head-col-last-fixed {
    position: sticky;
    right: 0;
    z-index: 2;
    background-color: white;
  }
  .col {
    &-last-fixed {
      height: 100%;
      position: sticky;
      right: 0;
      padding: 0 $base !important;
      background-color: white;
      z-index: 2;
      &:hover {
        background-color: $grey98;
      }
    }
  }
}
