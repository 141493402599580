@use "../../css/index" as *;

.activities-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  > .activities-head {
    height: 3.5rem;
    flex: 0 0 auto;
    border-radius: $halfRadius;
    display: flex;
    align-items: center;
    flex-direction: row;
    border-bottom: $borderline solid $grey90;
    padding: 0;
    padding-left: $large;
  }
  > .body {
    flex: 1 1 auto;
    height: $base;
    width: 100%;
    padding: $base $large $large $large;

    .table-root {
      width: 100%;
    }
  }
  >.body.e-invoicing {
    padding: 0;
    font-family: $sageFontFamily;
  }
  > .body.activity-table-v2 {
    background: #f5f5f5;

    .body-table {
      background: #f5f5f5;
      height: 100%;
    }
  }
  > .body.padding-v2 {
    padding: 0 0 $borderline 0;
  }
}

.activities-wrapper.e-invoicing{
  width: calc(100% - 16.25rem);
}

.table-activity-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  padding-bottom: $base;
  > .welcome-message-wrapper {
    margin-bottom: $small;
    > .welcome-message-name {
      @include h2;
      color: $babyBlue;
    }
    > .welcome-message-company {
      font-family: $primaryFontFamily;
      line-height: $large;
      font-size: $base;
      font-weight: 400;
      color: $grey35;
    }
  }

  > .body-table {
    display: flex;
    flex-grow: 1;
    background: $white100;
    border-radius: $fullRadius;
  }

  > .table-header-button {
    button.btn-primary {
      border-radius: 0.5rem;
      background: #143b52;
    }
    margin-bottom: 0.5rem;
  }
}

.table-activity-wrapper.activity-stream-v2 {
  padding-bottom: 0rem;

  .overlay-scrollbar-width-reset {
    height: 100%;
    .os-content-glue {
      width: 100%;
    }
  }
}

.table-button-divider {
  text-align: center;
  margin: auto $medium;
  color: $grey94;
}
