@use "../../../css/index" as *;

.sb-input {
  background-color: $grey98;
  border: $borderline solid transparent;
  color: #4d4d4d;
  font-family: $primaryFontFamily;
  height: $xlarge;
  font-size: 0.875rem;
  padding: 0;
  text-indent: $medium;
  width: 100%;
  border-radius: $atom;

  &::placeholder {
    font-style: normal;
    font-weight: normal;
    color: $grey70;
  }

  &:hover {
    background-color: $grey96;
  }

  &:focus {
    background-color: $grey93;
    border: $borderline solid $grey70;
  }

  &:focus-visible {
    outline: none;
  }

  &:focus::placeholder {
    color: transparent;
  }
  &:disabled {
    background-color: $grey98;
    color: $grey70;
    cursor: not-allowed;
  }
}
.sb-input.error {
  border: $borderline solid #b71c1c;
  border-radius: $tiny;
  font-family: $sageFontFamily;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.3125rem;
  text-align: left;
  color: #b71c1c;
}

.sb-label-container {
  display: inline-block;
  margin-bottom: $tiny;
}

.sb-label {
  color: $primary;
  @include caption-med;
  margin-bottom: 0.625rem;

  &-optional {
    font-weight: 400;
  }
}

.sb-error-container {
  > .sb-error {
    color: $red;
    font-size: 0.625rem;
    font-weight: 500;
    line-height: $medium;
  }
}

.sbIcon-wrapper {
  width: 13.75rem;

  > .sbIcon-label {
    display: block;
    color: $primary;
    font-weight: 600;
    font-size: $medium;
  }

  > .sbIcon-input-wrapper {
    width: 100%;
    position: relative;

    > .sbIcon-input {
      background-color: $grey98;
      border: $borderline solid transparent;
      display: inline-block;
      font-family: $primaryFontFamily;
      height: $xlarge;
      margin: $small 0;
      padding: 0.625rem $xlarge 0.625rem 0;
      text-indent: $medium;
      width: 100%;

      &::placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 0.875rem;
        line-height: $semi;
        color: $grey70;
      }

      &:focus {
        background-color: $grey93;
        border: $borderline solid $grey70;
        border-radius: $atom;
        color: $primary;
      }

      &:focus-visible {
        outline: none;
      }

      &:focus::placeholder {
        color: transparent;
      }
    }

    > svg {
      position: absolute;
      right: $medium;
      top: 50%;
      transform: translateY(-50%);
      fill: $grey60;
    }
  }
}
