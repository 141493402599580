@use "../../../css/index" as *;

.e-invoice-sidebar-container {
  height: 100%;
  width: 14.625rem;
  padding: 1.5rem 0.75rem;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  background: $white100;
  border-radius: 0.5rem;
  border: 0.0625rem solid $grey200;
  overflow: auto;
  .company-details-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
    .company-logo-container {
      display: flex;
      width: 2.25rem;
      height: 2.25rem;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.25rem;
      background: $grey90;
      border-radius: 50%;

      .userpic-btn-img {
        width: 2.25rem;
        height: 2.25rem;
        border-radius: 50%;
      }
    }
    .company-name-container {
      .company-name {
        margin: 0rem;
        color: $greyText;
        text-align: center;
        font-family: $sageFontFamily "Work Sans";
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem;
      }
    }
  }

  .nav-links-container {
    a {
      text-decoration: none;
      .nav-link-item {
        display: flex;
        height: 2rem;
        padding: 0.25rem 0.5rem;
        align-items: center;
        gap: 0.375rem;
        align-self: stretch;

        color: $greyText;
        text-align: center;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin-bottom: 0.4375rem;
        cursor: pointer;
        justify-content: space-between;
        > .hidden {
          display: none;
        }
      }
      & .active {
        background: $backgroundAWContent;
        border-radius: 0.25rem;
      }
      .nav-link-item:hover {
        background-color: $grey98;
        span {
          color: $selectionDark;
        }
      }
    }
  }
}
