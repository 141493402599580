@import "../../../css/index.scss";

.request-status-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 100%;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid #dee1ed;
  border-radius: 2px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  .status-logo {
    width: 5rem;
    height: 5rem;
  }
  .status-message {
    @include h2;
    text-align: center;
    color: $primary;
    width: 26rem;
    margin-bottom: $base;
    margin-top: $xlarge;
  }
  .status-ps {
    @include h3;
    font-weight: 400;
    text-align: center;
    width: 30rem;
  }
  .status-message,
  .status-ps {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .status-actions {
    margin-top: $base;
    display: flex;
    justify-content: center;
  }
}

.display {
  &-lg {
    width: 69rem;
    height: 47.75rem;
  }
  &-sm {
    width: 37.5rem;
    height: 36.75rem;
  }
}
