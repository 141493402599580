@use "../../../css/index" as *;

.linear-progress-bar-overrides.MuiLinearProgress-root {
  width: 100%;
  background-color: $white100;
  top: 0.04;
  height: 0.125rem;
  .MuiLinearProgress-bar {
    background: linear-gradient(90deg, #5955a5 5.24%, #ee398b 44.07%, #f9a456 68.87%, #00bad4 95.07%);
    animation-duration: 4s;
  }
  .MuiLinearProgress-bar1Indeterminate {
    display: none;
  }
}
