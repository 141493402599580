@use "../../../css/index" as *;

.uc-wrapper > .body > .right > .head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // background moved to MainContentSidebar.scss along with other workspace theme specifics
  height: 3.5rem;
  flex: 0 0 auto;
  > .accountInfo-wrapper {
    display: flex;
    flex-direction: row;
  }

  > .userInfo-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: $base;
    gap: 0.875rem;

    > .last-sync-wrapper {
      color: $grey60;
      gap: $small;
      display: flex;
      align-items: center;
      .light {
        padding-top: 0.07rem;
        @include body1;
        &-Failed {
          color: red;
          @include body1;
          margin-right: 0.5rem;
        }
        &-Success,
        &-Cancelled {
          @include body1;
          color: green;
          margin-right: 0.5rem;
        }
        & + .MuiIconButton-root {
          > svg {
            animation: rotate 2s linear infinite;
          }
        }
      }
      .light-animated {
        padding-top: 0.07rem;
        @include body1;
        width: 6.25rem;
      }

      .light-animated:after {
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        -webkit-animation: ellipsis steps(4, end) 900ms infinite;
        animation: ellipsis steps(4, end) 900ms infinite;
        content: "\2026"; /* ascii code for the ellipsis character */
        width: 0px;
      }

      @keyframes ellipsis {
        to {
          width: 1.25rem;
        }
      }

      @-webkit-keyframes ellipsis {
        to {
          width: 1.25rem;
        }
      }

      .bold {
        @include caption-med;
      }

      .MuiButtonBase-root {
        padding: 0;
      }
    }
    > .global-search-icon {
      cursor: pointer;
      height: 1.26rem;
      width: 1.26rem;
    }
    > .header-divider {
      height: 1.26rem;
      margin: 0 1rem 0 1.81rem;
      background: #8592ad;
    }
  }
}

// Set profile bar color themes for various workspace types here
.uc-wrapper > .body > .right > .head.ap-theme-content {
  background: $backgroundAPContent;
}

.uc-wrapper > .body > .right > .head.ar-theme-content {
  background: $backgroundARContent;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#last-sync {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;

  .MuiBox-root {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 0.5rem;
  }

  &.in-progress {
    .MuiIconButton-root {
      svg {
        animation: rotate 2s linear infinite;
      }
    }
  }
}

@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.25;
  }
}

#last-sync-indicator {
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 100%;

  // Handle Statuses
  &.status {
    &-Failed {
      background-color: red;
      box-shadow: 0 0 0.25rem red;
    }
    &-Success,
    &-Cancelled {
      background-color: green;
      box-shadow: 0 0 0.25rem green;
      animation: blink 750ms cubic-bezier(0.5, 0, 1, 1) infinite alternate;
    }
  }
}

.last-sync-tooltip-container {
  .last-sync-tooltip-text-container {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
    .last-sync-tooltip-text-header {
      font-weight: 700;
    }
  }
}
