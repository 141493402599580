@use "../../../css/index" as *;
$eInvoice_grey50: #fafafa;
$eInvoice_grey200: #eee;
$eInvoice_grey600: #757575;
$eInvoice_grey800: #424242;
$eInvoice_grey900: #212121;

.table-activity-wrapper.activity-stream-v2.e-invoices {
  font-family: $sageFontFamily;
  margin-right: 1.5rem;

  .body-table.e-invoices {
    .table-root {
      .table-pagination-container {
        border: $borderline solid $eInvoice_grey200;
        box-shadow: 0rem -0.1563rem 0.1563rem -0.0938rem $eInvoice_grey200;
        .MuiButtonBase-root,
        .MuiTypography-root {
          font-family: $sageFontFamily;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.3125rem;
        }
      }
      .table-caption-container {
        background: $eInvoice_grey50;
        border: $borderline solid $eInvoice_grey200;
        .table-caption-wrapper {
          .MuiTypography-root {
            font-family: $sageFontFamily;
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1.3125rem;
          }

          .user-actions {
            button.table-caption-action-button.caption-button {
              display: flex;
              height: 2.5rem;
              padding: 0.5rem 1rem;
              justify-content: center;
              align-items: center;
              gap: 1rem;
              border-radius: 0.5rem;
              background: #1a73e8;
              border: 0.0625rem solid #1a73e8;
              color: $white100;
              box-shadow:none;
            }
            button.table-caption-action-button.caption-button:active {
              background: #424242;
              color: #ffffff;
              border: 0.0625rem solid #424242;
            }
            button.table-caption-action-button.caption-button:hover {
              background: #0d47a1;
              color: #ffffff;
              border: 0.0625rem solid #0d47a1;
            }
            button.table-caption-action-button.caption-button:disabled {
              background: #757575;
              border: #ffffff;
              color: #ffffff;
            }
            button.table-caption-action-button.caption-button:focus {
              box-shadow: 0rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.1), 0rem 0.5rem 0.75rem 0rem rgba(0, 0, 0, 0.1);
            }
          }
        }
      }
      .table-caption-container.table-caption-selected-view {
        background: $eInvoice_grey800;
      }
      .table-view-container {
        border: $borderline solid $eInvoice_grey200;
        border-top: none;
        table {
          thead {
            tr {
              th:first-child {
                width: 1%;
                min-width: 4rem;
                padding-left: 0;
              }
              th:nth-child(4) {
                max-width: 1%;
                width: 1%;
                > div {
                  max-width: 1%;
                  width: 1%;
                }
              }
              th:not(first-child) {
                > div {
                  width: 10.063rem;
                  white-space: wrap;
                  text-wrap: wrap;
                  height: 3rem;
                }
                div.table-header-filter {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                }
              }
              th {
                border-bottom: $borderline solid $eInvoice_grey200;
                span.MuiCheckbox-root {
                  svg {
                    color: $eInvoice_grey600;
                  }
                }
                span.MuiTypography-root {
                  font-family: $sageFontFamily;
                  font-weight: 500;
                  font-size: 0.75rem;
                  line-height: 1.125rem;
                }
              }
              th:hover {
                span.MuiCheckbox-root {
                  svg {
                    color: $eInvoice_grey600;
                  }
                }
                span.MuiCheckbox-root.Mui-checked {
                  background: none;
                }
              }
              th {
                div {
                  div.table-header-cell-label {
                    span.table-sortIcon.hide-by-default {
                      display: block;
                    }
                  }
                }
              }
            }
          }
          tbody {
            tr {
              border-color: $eInvoice_grey200;
              td,
              th {
                border-bottom: $borderline solid $eInvoice_grey200;
                border-top: $borderline solid $eInvoice_grey200;
                height: 3.5rem;
                color: $eInvoice_grey800;

                span.MuiCheckbox-root {
                  svg {
                    color: $eInvoice_grey600;
                  }
                }
                span.MuiTypography-root {
                  font-family: $sageFontFamily;
                  font-weight: 400;
                  font-size: 0.875rem;
                  line-height: 1.3125rem;
                }
                span.unread.MuiTypography-root {
                  font-weight: 500;
                }
                &.unread > span.MuiTypography-root{
                  font-weight: 500;
                }
              }
              th:nth-child(4) {
                max-width: 1%;
                width: 1%;
                > div {
                  max-width: 1%;
                  width: 1%;
                }
              }
              td {
                .empty-title {
                  font-family: $sageFontFamily;
                  font-weight: 500;
                  font-size: 1.5rem;
                  line-height: 2rem;
                }
                .empty-caption {
                  font-family: $sageFontFamily;
                  font-weight: 400;
                  font-size: 1rem;
                  line-height: 1.5rem;
                }
              }
            }
            tr:first-child {
              td,
              th {
                border-top: none;
              }
            }
            tr.table-row-selected {
              background: $white100;
            }
          }
        }
      }
    }
  }
}

//style override for date-range filter
.gs-date-range-filters{
  .MuiPaper-root{
    margin-top: .5rem;
  }
}