@use "../../../css/index.scss" as *;

#lockstep-bar {
  .bar-icon-btn {
    border-radius: $atom;
    transition: none;
    padding: $small;
    &.active {
      background: $primary;
      svg path {
        fill: $white100;
      }
    }
    &:hover {
      background-color: $white100;
    }
  }
}

#lockstep-bar-menu {
  display: grid;
  grid-template-rows: repeat(auto, 1fr);
  padding: $medium;
  gap: $medium;
  background: $white100;
  box-shadow: 0rem 0.5rem 0.75rem rgba(0, 0, 0, 0.1), 0rem 0.25 1.25rem rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;

  .MuiMenuItem-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: $small;
    border-radius: $fullRadius;
    width: 4rem;
    background-color: transparent;
    white-space: wrap;
    text-align: center;

    p {
      @include body1;
      color: $primary;
      font-style: normal;
      font-weight: 500;
      font-size: 0.625rem;
      line-height: 0.75rem;
      letter-spacing: 0.025rem;
      text-transform: uppercase;
      margin-top: 0.625rem;
    }

    .MuiIconButton-root {
      width: 4rem;
      height: 4rem;
      background: $white100;
      border: 0.125rem solid $grey98;
      border-radius: 0.5rem;
      svg path {
        fill: $primary;
      }
    }

    .MuiIconButton-root:hover {
      border: 0.125rem solid $grey98;
      box-shadow: 0rem 0.5rem 0.75rem rgba(0, 0, 0, 0.1), 0rem 0.25rem 1.25rem rgba(0, 0, 0, 0.1);
      border-radius: 0.5rem;
      svg path {
        fill: $primary;
      }
    }
  }
}

.option-2 {
  grid-template-columns: repeat(2, 1fr);
}

.option-3 {
  grid-template-columns: repeat(3, 1fr);
  width: 16.375rem;
  height: 8.125rem;
}
