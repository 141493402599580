@use "../../../../../css/index" as *;

@mixin attachment-active {
  padding: $atom 0.1875rem;
  background: $onboardBorder;
  border: $borderline solid $grey90;
  border-radius: $atom;
}

@mixin body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  .header {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    gap: $base;
    .header-details {
      display: flex;

      .chip {
        height: $semi;
        padding: $atom $tiny;
        width: fit-content;
        margin-left: $small;
      }
      .body2 {
        color: $black;
        span.activity-creator {
          display: inline-flex;
        }
        .activity-variant {
          color: $grey40;
        }
      }
      .tag.attachments {
        height: $semi;
        font-style: normal;
        font-weight: 500;
        font-size: $medium;
        display: flex;
        align-items: center;
        padding: $atom $tiny;
        letter-spacing: $tiny;
        text-transform: uppercase;
        color: $grey60;
        flex: none;
        flex-grow: 0;
        margin: 0 0 0 $tiny;
        cursor: pointer;
      }
      .tag.attachments.active {
        @include attachment-active;
      }
    }
    .header-date {
      @include caption;
      color: $grey40;
      gap: $base;
      align-self: center;
    }
    &-flag {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      &-icon {
        margin: 0 $tiny;
        display: flex;
        & svg > path {
          width: $base;
          height: $base;
        }
      }
      &-title {
        @include body1;
        line-height: $base;
      }
    }
    & .purple {
      color: #7d4ed8;
      & svg > path {
        fill: #7d4ed8;
      }
    }
    & .green {
      color: #24b14b;
      & svg > path {
        fill: #24b14b;
      }
    }
    & .orange {
      color: #f38923;
      & svg > path {
        fill: #f38923;
      }
    }
  }
  .action-wrapper {
    display: flex;
    flex-direction: row;

    button {
      margin-right: $tiny;
    }
  }
  .subheader-wrapper {
    margin-bottom: $small;
  }
  .subheader {
    color: $grey60;
    margin-top: $tiny;
    margin-right: 4.5rem;
    word-break: break-all;
    white-space: normal;
    cursor: pointer;
  }
  > .subheader-container {
    margin-bottom: $large;
    > .subheader {
      color: $grey60;
      margin-top: $tiny;
      margin-right: 4.5rem;
      word-break: break-all;
      white-space: normal;
    }
  }
  > .content {
    color: $black;
    min-width: 0;
  }
  .invalid-email-body {
    line-height: $semi;
    word-break: break-all;
    .bold {
      font-weight: bold;
    }
    .para {
      padding-bottom: $base;
    }
  }
  > .email-renderer {
    width: 100%;
    height: 100%;
    border: none;
    color: #4d4d4d;
  }

  .email-renderer.full-height {
    max-height: 4.875rem;
    overflow: hidden;
    user-select: none;
  }

  .email-container,
  .invalid-email-body {
    width: 100%;
    height: fit-content;
    @include body1;
    word-break: break-word;
    border: none;
    color: #4d4d4d;

    mark {
      background-color: rgba(243, 137, 35, 0.5);
    }
  }
  .expanded-margin {
    margin-bottom: 0;

    > p {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  > .snooze-feed {
    p {
      line-height: $semi;
      margin-top: 0;
      font-size: 0.875rem;
    }
  }

  > .button-container {
    .retry-email {
      padding: $tiny $small;
    }
  }
  .close-container {
    background-color: #ffffff;
    display: flex;
    position: absolute;
    top: -$small;
    right: $tiny;
    padding: $tiny;
  }
}

@mixin activity-feed {
  > .head {
    > .icon {
      height: $xlarge;
      width: $xlarge;
      border-radius: 50%;
      background-color: $grey98;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        fill: $grey60;
      }
    }
    > .icon-snoozed {
      height: $xlarge;
      width: $xlarge;
      border-radius: 50%;
      background-color: transparent;
      border: solid #d1e7ef $borderline;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .body {
    @include body;
  }
}

.attachments-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px;
  background: $white100;
  border: $borderline solid $grey90;
  box-sizing: border-box;
  border-radius: $atom;
  margin: $base 0;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  position: relative;

  .list-wrapper {
    max-width: 60%;
    .attachments-container {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      overflow: auto;
      white-space: nowrap;
      row-gap: $tiny;
      column-gap: $medium;
      width: 26.3125rem;
    }
  }
  .attachments-action {
    display: flex;
    justify-content: flex-end;
    justify-items: center;

    .attachments-button-wrapper {
      display: flex;
      justify-content: space-between;
      column-gap: $small;
    }
    .download-all {
      white-space: nowrap;
    }
    .btn.btn-secondary.download-all {
      background: $grey98;
      border: $borderline solid $grey90;
      box-sizing: border-box;
      border-radius: $atom;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 0 $small;
      font-weight: 600;
      font-size: $medium;
      line-height: $base;
      display: flex;
      align-items: center;
      color: $primary;
      height: $large;
      padding: $tiny $small;
    }
  }
}
.activity-feed-item-card {
  background-color: #fff;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 15%);
  border-radius: 8px;
  padding: $large $base;
  margin: 0.625rem 0;
}

.activity-feed-item-wrapper {
  display: flex;
  min-height: 5.5rem;
  @include activity-feed;
  margin-bottom: 0.625rem; // 10px
  margin-top: 0.625rem;
  padding: $semi $semi;
  background-color: #fff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  > .head {
    padding-right: $large;
    flex: 0 0 auto;
    &:hover {
      cursor: pointer;
    }
  }
  > .body {
    flex: 0 1 auto;
    &:hover {
      cursor: pointer;
    }
  }
}

.modal-activity-feed-item-render {
  width: 63.6875rem;
  min-height: 35rem;
  transition: min-height 10s;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: $tiny;
  display: flex;
  flex-direction: column;
  max-height: 80%;

  .os-host,
  .os-host-overflow {
    overflow: hidden !important;
    max-height: 25rem;
    padding-right: 6rem;
  }

  .modal-close-icon {
    position: absolute;
    right: 0.91375rem;
    top: 1.04rem;
    svg {
      fill: #576375;
      cursor: pointer;
    }
  }
  .modal-content-activity-feed {
    display: grid;
    grid-template-columns: $xlarge calc(100% - 4rem);
    min-height: 5.5rem;
    gap: $large;
    padding: $large 0;
    @include activity-feed;
    padding: 2.0625rem $xlarge $xlarge;

    .original-body {
      @include body;
      display: block;

      .header {
        display: flex;
        gap: 1rem;
        justify-content: flex-start;
        > .attachments {
          display: flex;
          align-items: center;
          padding: 0.1875rem $tiny;
        }
        > .active {
          @include attachment-active;
        }
      }
    }

    .attachments-wrapper {
      display: flex;
      flex-direction: row;
      padding: 9px;
      background: $white100;
      border: $borderline solid $grey90;
      box-sizing: border-box;
      border-radius: $atom;
      margin-top: $base 0;
      justify-content: space-between;
      width: 100%;
      position: relative;
      align-items: center;

      .attachments-list {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% - 9.125rem);
        padding: $small 0 0 $small;
        border-right: $borderline solid $grey90;
      }
      .attachments-action {
        padding: $small 0;
        width: 9.125rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        align-content: center;
        justify-items: center;
        height: 100%;
        .attachments-button-wrapper {
          display: flex;
          justify-content: space-between;
        }
        .btn.btn-secondary.download-all {
          background: $grey98;
          border: $borderline solid $grey90;
          box-sizing: border-box;
          border-radius: $atom;
          flex: none;
          order: 1;
          flex-grow: 0;
          margin: 0 $small;
          font-weight: 600;
          font-size: $medium;
          line-height: $base;
          display: flex;
          align-items: center;
          color: $primary;
          height: $large;
          padding: $tiny $small;
        }
      }
    }
  }
}

.magic-link-cta-container {
  margin: 8px 0px 8px 0px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;

  a.magic-link-cta {
    text-decoration: none;
    margin-right: 8px;
  }

  a.magic-link-cta.disabled {
    background-color: #143b52;
    opacity: 0.4;
    cursor: auto;
    pointer-events: none;
  }

  p.magic-link-cta-eta {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #000000;
  }
}

.activity-action-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.625rem 0 0 0;

  > .left {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 0.625rem;
    .btn {
      padding-left: $small;
      padding-right: $small;
      // margin-top: $tiny;
    }
    > * {
      margin-right: 0.625rem;
    }
  }

  > .right {
    display: flex;
    align-items: center;

    > .automate-time-wrapper {
      display: flex;
      height: $large;
      align-items: center;
      > .time-text-wrapper {
        font-family: $primaryFontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: $medium;
        line-height: $base;
        color: #8592ad;
        padding: 0 $small;
      }
    }
  }
  .reply-action .MuiIconButton-root,
  .tooltip-actions .MuiIconButton-root,
  .emoji-actions .MuiIconButton-root {
    svg {
      height: $base;
      width: $base;
    }

    &:hover,
    &:hover svg path {
      fill: $primary !important;
    }
  }

  .tooltip-actions .MuiIconButton-root svg {
    transform: rotate(90deg);
  }
}

.reaction-tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.5rem;
  background-color: #fff;
  box-shadow: 0px $borderline $atom rgba(0, 0, 0, 0.25);
  border-radius: 0.375rem;
  @include caption-sm;
  cursor: pointer;
  .reaction {
    @include body1;
    margin-right: 0.5rem;
  }
}

.reaction-tag-highlight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.5rem;
  background-color: #fff;
  box-shadow: 0px $borderline $atom rgba(0, 0, 0, 0.35);
  border-radius: 0.375rem;
  @include caption-sm;
  cursor: pointer;
  .reaction {
    @include body1;
    margin-right: 0.5rem;
  }
  margin-right: 0.5rem;
  color: $primary;
  font-weight: 600;
}

.reaction-tag:hover,
.reaction-tag-highlight:hover {
  box-shadow: 0 $small $small rgba(0, 0, 0, 0.25);
  background-color: $grey96;
}

.emoji-tooltip {
  background: $white100 !important;
  margin: 0 !important;
  padding: 0 !important;
  border-radius: $atom;
  box-shadow: 0 $small $medium rgba(0, 0, 0, 0.1), 0 $tiny $semi rgba(0, 0, 0, 0.1);
  display: flex;
  > .MuiMenuItem-root {
    padding: 0.5rem;
  }
}
