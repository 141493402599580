@import "../../../../../../../css/index";

.sharing-initiated {
  display: grid;
  grid-template-columns: 4.25rem 89%;

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-body {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    &-header {
      @include h3;
      color: $grey30;
      padding-bottom: 0.25rem;
    }
    &-text {
      @include body1;
      font-weight: 400;
      width: 29.81rem;
      color: $grey30;
    }
  }
}

.receiver-list {
  color: $grey40;
  @include caption-med;
}
