$primaryFontFamily: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif;

// Sage UI fonts

@font-face {
  font-family: 'Sage UI';
  src: url('../../public/fonts/Sage_UI-Regular.woff2') format('woff2'),
       url('../../public/fonts/Sage_UI-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sage UI';
  src: url('../../public/fonts/Sage_UI-Medium.woff2') format('woff2'),
       url('../../public/fonts/Sage_UI-Medium.woff') format('woff');
  font-weight: 500; /* Medium font weight */
  font-style: normal;
}

@font-face {
  font-family: 'Sage UI';
  src: url('../../public/fonts/Sage_UI-Bold.woff2') format('woff2'),
       url('../../public/fonts/Sage_UI-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

$sageFontFamily: "Sage UI";

$fontSizeXXS: 0.625rem;
$fontSizeXS: 0.75rem;
$fontSizeSM: 0.875rem;
$fontSizeMD: 1rem;
$fontSizeL: 1.125rem;
$fontSizeXL: 1.5rem;
$fontSizeXXL: 2.5rem;

$lineHeightXS: 0.75rem;
$lineHeightSM: 1rem;
$lineHeightMD: 1.25rem;
$lineHeightL: 1.5rem;
$lineHeightXL: 2rem;
$lineHeightXXL: 3.5rem;

@mixin h1 {
  font-family: $primaryFontFamily;
  font-weight: 600;
  font-size: $fontSizeXXL;
  line-height: $lineHeightXXL;
  margin: 0;
}

@mixin h2 {
  font-family: $primaryFontFamily;
  font-weight: 600;
  font-size: $fontSizeXL;
  line-height: $lineHeightXL;
  margin: 0;
}

@mixin h3 {
  font-family: $primaryFontFamily;
  font-weight: 600;
  font-size: $fontSizeL;
  line-height: $lineHeightL;
  margin: 0;
}

@mixin h4 {
  font-family: $primaryFontFamily;
  font-weight: 600;
  font-size: $fontSizeMD;
  line-height: $lineHeightL;
  margin: 0;
}

@mixin body1 {
  font-family: $primaryFontFamily;
  font-weight: 400;
  font-size: $fontSizeSM;
  line-height: $lineHeightMD;
  margin: 0;
}

@mixin body2 {
  font-family: $primaryFontFamily;
  font-weight: 500;
  font-size: $fontSizeSM;
  line-height: $lineHeightMD;
  margin: 0;
}

@mixin body3 {
  font-family: $primaryFontFamily;
  font-weight: 600;
  font-size: $fontSizeSM;
  line-height: $lineHeightMD;
  margin: 0;
}

@mixin body4 {
  font-family: $primaryFontFamily;
  font-weight: 600;
  font-size: $fontSizeXS;
  line-height: $lineHeightMD;
  margin: 0;
}

@mixin caption {
  font-family: $primaryFontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: $fontSizeXS;
  line-height: $lineHeightSM;
  margin: 0;
}

@mixin caption-med {
  font-family: $primaryFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: $fontSizeXS;
  line-height: $lineHeightSM;
  margin: 0;
}

@mixin caption-sm {
  font-family: $primaryFontFamily;
  font-weight: 500;
  font-size: $fontSizeXXS;
  line-height: $lineHeightXS;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.025rem;
}

@mixin link {
  font-family: $primaryFontFamily;
  font-weight: 400;
  font-size: $fontSizeSM;
  line-height: $lineHeightMD;
  margin: 0;
  text-decoration: underline;
}

// These rule are copies of the corresponding classes above so we can apply our default styles for <Hx> elements without using a className attribute
h1 {
  font-family: $primaryFontFamily;
  font-weight: 600;
  font-size: $fontSizeXXL;
  line-height: $lineHeightXXL;
  margin: 0;
}

h2 {
  font-family: $primaryFontFamily;
  font-weight: 600;
  font-size: $fontSizeXL;
  line-height: $lineHeightXL;
  margin: 0;
}

h3 {
  font-family: $primaryFontFamily;
  font-weight: 600;
  font-size: $fontSizeL;
  line-height: $lineHeightL;
  margin: 0;
}

h4 {
  font-family: $primaryFontFamily;
  font-weight: 600;
  font-size: $fontSizeMD;
  line-height: $lineHeightL;
  margin: 0;
}
