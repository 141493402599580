@use "../../css/index" as *;

.customers-wrapper {
  display: grid;
  grid-template-rows: 3.5rem auto;
  width: 100%;
  height: 100%;
  > .customer-head {
    border-radius: $halfRadius;
    display: flex;
    align-items: center;
    flex-direction: row;
    border-bottom: $borderline solid $grey90;
    padding: 0;
    padding-left: $large;
  }
  > .body {
    display: flex;
    padding: $large $large 0 $large;
  }
  > .body.padding-v2 {
    padding: 0 0 $borderline 0;
  }

  // This is to prevent overlayScrollbars malfunction
  //with width overflowing screen size
  .os-content-glue {
    width: 100% !important;
  }
}

.customer-detail-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: $base;
  > .head {
    width: 100%;
    height: 3.5rem;
    border-radius: $atom;
    background: $grey98;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $small $base $small $base;
    flex-shrink: 0;
    > .left {
      display: flex;
      align-items: center;
      justify-content: center;
      > .tag {
        display: flex;
        align-items: center;
        justify-content: center;
        width: $larger;
        height: $larger;
        background-color: $grey90;
        border-radius: $atom;
        font-size: 0.875rem;
        line-height: $semi;
        margin: 0;
        font-family: $primaryFontFamily;
        font-weight: 600;
        text-transform: uppercase;
      }
      > .customerName {
        @include body2;
        color: $grey10;
        margin-left: $base;
      }
    }
    > .right {
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 1.5rem;
      > .amounts-wrapper {
        margin-left: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: $xlarge;
      }
      > .amounts-separator {
        & div:last-child {
          padding: $tiny $base $tiny 0;
          border-right: 1px solid $grey80;
        }
      }
    }
  }
  > .body {
    display: grid;
    grid-template-columns: 1.8fr 1fr;
    flex-grow: 1;
    margin-top: $base;
    background: $white100;
    border: $borderline solid $grey94;
    box-shadow: 0 $borderline $atom rgba(0, 0, 0, 0.15);
    border-radius: $tiny;
    position: relative;
    > .left {
      display: flex;
      flex-direction: column;
      padding: $large;
      gap: $larger;
    }
    > .left-empty-state {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  > .body-table {
    display: flex;
    flex-grow: 1;
    margin-top: $base;
    background: $white100;
    border-radius: $fullRadius;
    flex-direction: column;

    .header-button-container {
      margin-bottom: 0.5rem;

      .btn {
        border-radius: 0.5rem;
      }
    }
    .table-root {
      width: 100%;
    }
  }
  .activity-flyout {
    .header {
      color: $primary;
      margin-bottom: $small;
    }
    .subheader {
      color: $grey40;
      margin-bottom: $base;
    }
    .sbIcon-wrapper {
      width: auto;
      .sbIcon-input {
        margin: 0;
      }
    }
  }
  label {
    @include caption-med;
    display: inline-block;
    color: $primary;
    margin-bottom: $tiny;
  }
  label + .ta-wrapper .ta-input {
    margin-top: 0;
    margin-bottom: $base;
  }
  .btn-grp {
    display: flex !important;
    align-items: center;
    margin-top: $small;
  }
  .recap-header {
    margin-top: 2.25rem;
    color: $grey60;
  }
  ul.recap-list {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    > li.recap-item {
      display: flex;
      flex-direction: column;
      .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: $tiny;
        .caption {
          color: $grey60;
        }
      }
    }
    > li.recap-item:not(:last-child) {
      margin-bottom: $large;
    }
  }
}

.documents-wrapper {
  width: 100%;
  height: 100%;

  .header-button {
    .btn {
      border-radius: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
}

.documents-wrapper.with-header-btn {
  height: calc(100% - 40px);
}

.col-align-left,
.col-align-right {
  > .last_activity_data,
  .table-formatted-cell {
    margin: 0;
    font-weight: 400;
    color: $grey10;
  }
}
