@use "../../../../css/index" as *;

.workspace-switcher-wrapper {
  margin-top: $base;
  margin-bottom: $larger;
  .switcher-container {
    display: flex;
    gap: $small;
    flex-direction: row;
    align-items: center;
    flex: 1 1 auto;
    width: 100%;
    .workspace-switcher-item {
      @include body2;     
      height: $xlarge;
      padding: 0 $medium;
      background: $white100;
      border-radius: $atom;
      cursor: pointer;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      color: $primary;
      border: $borderline solid $grey30;

      &:hover {
        border: $borderline solid $selectionDark;
        background: $grey98;
        color: $selectionDark;
      }
    }

    .workspace-add-more {
      height: $xlarge;
      background: transparent;
      border-radius: $atom;
      border: $borderline solid $grey30;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      color: $grey30;
      cursor: pointer;
      svg {
        fill: $grey30;
      }
    }
    .workspace-add-more:hover,
    .workspace-add-more:active,
    .workspace-add-more:focus {
      border: $borderline solid $selectionDark;
      background: $grey98;
      color: $selectionDark;
      svg {
        fill: $selectionDark;
      }
    }

    .workspace-switcher-item.active {
      width: 100%;
      background: $grey30;
      color: $white100;
      padding: 0 $tiny;
    }

    .workspace-switcher-item.dropdown {
      width: 100%;
      background: $grey30;
      color: $white100;
      .workspace-title {
        width: 85%;
      }
    }
  }
}

.MuiModal-root.MuiMenu-overrides.workspace-selector {
  .MuiPaper-root.MuiPaper-elevation {
    margin-top: $small;
    padding: $small 0;
    width: 13rem;
  }
}