@use "../../../../../../../css/index" as *;

.e-invoice-actions {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
  justify-content: space-between;
  border-radius: 0.5rem;
  border: 0.0625rem solid #eee;
  padding: 1rem;
  .actions-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    p {
      margin: 0;
    }
    p.header {
      font-family: $sageFontFamily;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
    p.sub-header {
      color: #757575;
      font-family: $sageFontFamily;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;

      a {
        text-decoration-line: underline;
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    align-items: center;

    p.action-text-refused {
      color: #757575;
      font-family: $sageFontFamily;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }

  .MuiButton-root {
    .btn-text {
      font-family: $sageFontFamily;
      font-weight: 500;
      font-size: 0.857rem;
    }
  }
  .MuiButton-root.refuse {
    .btn-text {
      color: #212121;
      font-size: 0.75rem;
    }
  }
}
