@use "../../../../../css/index" as *;

.attachment-card {
  padding: $medium;
  box-shadow: none;
  border: $borderline solid $grey80;
  border-radius: $small;
  max-height: "22rem";
  overflow-y: "scroll";
  .MuiCardActions-root {
    @include body3;
    .MuiTypography-root {
      text-decoration: none;
    }
  }
}
