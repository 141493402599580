@use "../../css/index" as *;

.uc-wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  transition: all;
  transition-duration: 300ms;

  // .head defined in MainContentHead.scss

  > .body {
    height: $base;
    flex: 1 1 auto;
    display: flex;

    // .left defined in  MainContentSidebar.scss

    > .right {
      flex: 1 1 auto;
      border-left: $borderline solid $grey90;
      border-top: $borderline solid $grey90;
      width: calc(100% - 14.875rem);
      border-radius: $halfRadius;

      .head.activity-stream-v2 {
        background: $white100;
        border-bottom: 1px solid $grey200;
      }

      .head.activity-stream-v2.e-invoicing {
        height: 4.375rem;
      }

      > .content {
        height: 100%;
        background-color: $white100;
        width: 100%;
        overflow: auto;
        padding: 0rem;
        display: flex;
        flex-direction: row;

        .sidebar-container.e-invoicing {
          height: 100%;
          background: $grey100;
        }

        > div {
          > .os-host {
            > .os-padding {
              > .os-viewport {
                > .os-content {
                  > .body {
                    padding: $base $large 0 $large;
                    height: 100%;
                    width: calc(100vw - 21.0625rem);
                  }
                  .body.table-v2 {
                    background: $grey100;
                  }
                  .body.padding-v2 {
                    padding: 0 0 $borderline 0;
                  }
                }
              }
            }
          }
        }

        //Override search results page scroll behaviour
        .gsp-nav-wrapper.e-invoice {
          .body.e-invoice {
            .gsp-component-wrapper.e-invoice {
              .gsp-component-container {
                .table-with-infinite-scroll-container {
                  overflow: hidden;
                  .table-with-infinite-scroll-main-container {
                    .MuiTableContainer-root {
                      min-height: auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
      > .content.e-invoicing {
        padding: 1.5rem;
        padding-right: 0rem;
        overflow-y: hidden;
        grid-gap: 1.5rem;
        background: #f5f5f5;
        width: 100vw;
      }
    }

    > .right.activity-stream-v2.e-invoicing {
      height: calc(100% - 4.375rem);
      font-family: $sageFontFamily;
    }

    > .right.activity-stream-v2 {
      height: calc(100% - 3.5rem);
      > .content {
        > div {
          > .os-host {
            > .os-padding {
              > .os-viewport {
                > .os-content {
                  > .body {
                    width: 100%;
                  }
                  > .body.table-v2 {
                    padding-bottom: 1rem;
                    .body-table {
                      background: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // Pendo Guide Styles
  &.pendo__self_service_banner {
    padding-top: 3rem;
  }
}
