@import "../../../css/base";

@mixin h2-dialogue {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
  letter-spacing: 0rem;
  text-align: left;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.discard-draft-modal {
  .action-bar {
    justify-content: flex-end;
  }
  h2.dialogue-title {
    @include h2-dialogue;
  }
}
.discard-draft-variant-v2 {
  .MuiPaper-root {
    padding: 1.5rem !important;
    width: 33rem;
  }
  h2.dialogue-title {
    @include h2-dialogue;
  }
  div.MuiDialogContent-root {
    margin-top: 0rem;
    p.dialogue-message {
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1rem;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 1rem;
    }
  }
  .action-bar {
    gap: 0.625rem;
    span {
      margin: 0rem;
      > button.btn.btn-lg {
        display: flex;
        padding: 0.625rem 0.75rem;
        align-items: flex-start;
        flex: 1 0 0;
        border-radius: 0.25rem;
        font-style: normal;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.25rem;
        text-align: center;
        text-transform: none;
        border: none;
      }
      > button.btn.btn-lg.btn-primary {
        color: $white100;
        background: $babyBlue;
      }
      > button.btn.btn-lg.btn-primary:hover {
        background: $selectionDark;
      }
      > button.btn.btn-lg.btn-primary:active {
        background: $greyText;
      }
      > button.btn.btn-lg.btn-secondary {
        background: $emailSuperLightfill;
        color: $babyBlue;
      }
      > button.btn.btn-lg.btn-secondary:hover {
        background: $toolbarBtnActive;
        color: $selectionDark;
      }
      > button.btn.btn-lg.btn-secondary:active {
        background: $babyBlue;
        color: $white100;
      }
    }
  }
}
