@import "../../../../../../../css/index.scss";

.base-wrapper.Error {
  .details-wrapper.v2 {
    background: $redLight;
    .header-row {
      .left {
        .container {
          p {
            color: $redDark;
          }
        }
      }
    }
  }
}
