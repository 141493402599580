@use "../../../css/index" as *;

.contact-show-more-container {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: $small;
  width: 18.75rem;
  max-height: 31rem;
  min-height: auto;
  overflow-x: hidden;
  position: relative;
  box-shadow: 0 $small $medium rgba(0, 0, 0, 0.1), 0 $tiny $semi rgba(0, 0, 0, 0.1);
  .show-contacts-list {
    width: 18.75rem;
    .contact-item {
      display: flex;
      flex-direction: row;
      gap: $base;
      align-items: center;
      border-bottom: 0.0625rem solid #eeeeee;
      height: 6rem;
      width: 18.75rem;
      padding: $large $large $base $large;
      cursor: pointer;
      .contact-avatar-container {
        height: 3.5rem;
        width: 3.5rem;
        background: $white100;
        border: 0.0313rem solid black;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 1.6875rem;
        text-align: center;
        color: black;
      }
      .contact-details-container {
        display: flex;
        flex-direction: column;
        gap: $atom;
        align-items: flex-start;
        justify-content: center;
      }
    }
  }
  .button-container {
    background: white;
    height: 4rem;
    width: 18.75rem;
    display: flex;
    align-items: center;
    justify-content: end;
    padding: $base;
    border-radius: 0 0 $small $small;
    bottom: 0;
    position: sticky;
  }
}

.contact-card-wrapper {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: $small;
  box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.15);
  .back-button-container {
    display: flex;
    align-items: center;
    padding: $base;
    border-bottom: 0.0625rem solid #eeeeee;
    height: 3.5rem;
  }
}
