@use "../../../../css/index" as *;

.company-header-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: $xlarge;
  cursor: pointer;

  > .company-header-name-container {
    display: flex;
    flex-direction: row;

    > .company-name {
      @include h4;
      font-weight: 400;
    }
  }
}

.MuiModal-root.MuiMenu-overrides.company-header-dropdown {
  .MuiPaper-root.MuiPaper-elevation {
    margin-left: $medium;
    margin-top: $base;
  }

  .MuiMenuItem-overrides.MuiMenuItem-root {
    color: $grey30;

    &:hover {
      background-color: $grey98;
    }
  }
}
