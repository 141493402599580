@use "../../../css/index" as *;
@use "../../../css/spacing" as *;
$borderBottom: #e5e5e5;

@mixin placeholderStyle {
  opacity: 1;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  color: $grey60 !important;
  -webkit-text-fill-color: $grey60 !important;
}

.os-padding,
.os-scrollbar {
  z-index: 2 !important;
}

.na-wrapper-card.activity-create-card {
  &-closed {
    display: none;
  }
  margin-top: 1rem;
  position: relative;
  z-index: 10;
  bottom: 0;
  left: 0rem;
  width: 100%;
  display: flex;
  border-radius: 0.5rem;
  flex-direction: column;
  background-color: $white100;
  box-shadow: none;
  max-height: none;

  > .field-validation-message-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 1rem;
    gap: 1rem;
    position: absolute;
    height: 2.5rem;
    width: fit-content;
    top: 0rem;
    background: $redDark;
    color: $white100;
    border-radius: 0rem 0rem 1rem 1rem;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  > .na-header {
    width: 100%;
    height: 100%;
    border-radius: 0.3125rem 0.3125rem 0 0;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: $medium $base;
    align-items: center;

    > .reply-forward-tag-header {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 1.75rem;
      padding: 0 $small 0 $small;
      border-radius: $tiny;
      gap: $small;
      color: $white100;
      background: $emailType;
      margin-right: auto;
      > span {
        font-family: $sageFontFamily;
        font-size: $medium;
        font-weight: 400;
        line-height: 1.125rem;
        text-align: left;
      }
    }

    > .na-header-title-text {
      @include body2;
      width: calc(90% - 3rem);
      color: $white100;
      margin: 0.625rem $base;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    > .new-activity-header-close-wrapper {
      > svg {
        fill: $primary;
        &:hover {
          cursor: pointer;
        }
      }
    }

    .new-activity-type-selector-container {
      margin-right: auto;
      .activity-type-selector {
        .drop-menu.drop-menu-active {
          height: 2.1875rem;
          width: fit-content;
          border-radius: $tiny;
          padding: 0.375rem $small;
          .append-icon {
            background-color: transparent;
            margin: 0;
            > svg {
              transform: rotate(-360deg);
              height: 0.625rem;
              path {
                fill: $white100;
              }
            }
          }
          .body2 {
            color: $white100;
            text-transform: capitalize;
          }
          > svg {
            margin: 0.3125rem;
            padding: 0.1875rem;
            background-color: $white100;
            height: $base;
            width: $base;
            border-radius: $tiny;
            transform: rotate(180deg);
            path {
              fill: $greyText;
            }
          }
        }
        .drop-menu.drop-menu-active.activity-type-dropdown-open {
          > svg {
            transform: rotate(-360deg);
          }
        }
        .email {
          background-color: $emailType;
        }
        .request_customer_profile,
        .share_ap_profile,
        .share_ar_profile,
        .note {
          background-color: $greyText;
        }
        .phone_call {
          background-color: $phoneType;
        }
        .approval_request {
          background-color: $darkGreen;
        }
      }
    }
  }

  > .na-body-card {
    color: $primary;
    display: flex;
    flex-direction: column;
    padding: 0rem 1rem 0rem;

    > .email-head-wrapper {
      padding: 0.5rem 1.25rem 0.5rem;
      border-bottom: 0.0625rem solid $borderBottom;
      > table.email-head-section {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
        tr {
          td {
            > .na-body-to-To,
            .na-body-to-Cc,
            .na-body-to-Bcc {
              display: flex;
              flex-direction: row;
              align-content: left;
              font-weight: 500;
              align-items: center;
              font-size: 0.875rem;
              line-height: 0.75rem;
              height: 1.25rem;
              p {
                margin: 0rem;
                color: #4d4d4d;
              }
            }
            .na-body-to-extra-recipients {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              height: 1.5rem;
              .na-body-to-extra-cc,
              .na-body-to-extra-bcc {
                @include body3;
                font-size: 0.875rem;
                line-height: $base;
                display: flex;
                align-items: center;
                color: $babyBlue;
                margin-left: $tiny;
                cursor: pointer;
                padding: $tiny $small;
                height: $large;
                border: none;
              }
              .na-body-to-extra-bcc {
                margin-right: $tiny;
              }
            }
          }
          td:last-child {
            border-bottom: 0.0625rem solid $grey94;
          }
        }
        tr:not(:nth-child(1)) {
          height: 2.125rem;
          td {
            height: 2.125rem;
            .na-body-to.na-body-child {
              height: 2.125rem;
              align-items: center;
              .na-body-toline,
              .na-body-ccline,
              .na-body-bccline,
              .na-body-activity-type-line {
                height: 2.125rem;
                .MuiAutocomplete-root {
                  min-height: 2.125rem;
                  height: none;
                  .MuiFilledInput-root {
                    min-height: 2.125rem;
                    height: none;
                    .MuiChip-root {
                      height: 1.5rem;
                    }
                    .MuiFormControl-root {
                      .MuiInput-root {
                        font-weight: 500;
                        align-items: center;
                        font-size: 0.875rem;
                        line-height: 0.75rem;
                        height: 1.25rem;
                        color: #4d4d4d;
                        -webkit-text-fill-color: #4d4d4d !important;
                      }
                    }
                  }
                  input {
                    color: #4d4d4d;
                    -webkit-text-fill-color: #4d4d4d !important;
                  }
                  .MuiFilledInput-input {
                    min-height: 1.5rem;
                  }
                }
                &:focus-within {
                  border: none;
                  border-bottom: none;
                  background-color: $white100;
                }
              }
            }
            .na-body-title.na-body-child.na-body-template-selector {
              height: 1.5rem;
              width: 100%;
              div {
                display: flex;
                align-items: center;
                margin-left: 0rem;
                input {
                  margin: 0rem;
                }
                input::placeholder {
                  @include placeholderStyle;
                }
              }
            }
            .field-validation-container {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-right: 0.75rem;
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 400;
              line-height: 1.25rem;
              color: $grey60;
            }

            .na-body-title.na-body-child {
              .sb-wrapper {
                .sb-input {
                  border: none;
                  line-height: 0.75rem;
                  height: 1.5rem;
                }
              }
            }
            .subject-line {
              width: 100%;
              .sb-input {
                text-indent: 0%;
              }
              input::placeholder {
                @include placeholderStyle;
              }
            }
          }
        }
        tr:last-child {
          td {
            border-bottom: none;
          }
          td:last-child {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }
      }
    }

    > .na-body-child {
      margin: $small 0;
    }
    .na-body-to,
    .na-body-cc,
    .na-body-bcc {
      display: flex;
      flex-direction: row;
      max-width: 100%;
      > .na-body-toline,
      .na-body-ccline,
      .na-body-bccline,
      .na-body-activity-type-line {
        min-height: 24px;
        width: 100%;
        background-color: $white100;
        border: $borderline solid transparent;
        border-radius: $atom;
        max-height: 4.875rem;
        display: flex;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
        .na-body-toline-chip {
          display: flex;
          align-items: center;
          .na-body-toline-tooltip {
            display: flex;
            align-items: center;
            > svg {
              width: $semi;
              height: $semi;
              > path {
                fill: $tooltipBg;
              }
            }
          }
        }
        > .na-body-to-To,
        .na-body-to-Cc,
        .na-body-to-Bcc {
          display: flex;
          align-items: center;
          height: $xlarge;
          margin-left: $medium;
          margin-right: $medium;
        }
        > .na-body-to-search,
        .na-body-cc-search,
        .na-body-bcc-search {
          display: flex;
          align-items: center;
          height: $xlarge;
          margin-left: $medium;
          margin-right: 0.625rem;
        }
        &:hover {
          background-color: $white100;
        }

        .MuiAutocomplete-root {
          min-height: $xlarge;
          max-width: 100%;
          width: 100%;
          height: auto;
          .MuiFilledInput-root {
            display: flex;
            gap: $small;
            min-height: $xlarge;
            height: auto;
            background: none;
            .MuiChip-root {
              padding: 0;
              background-color: $white100;
              height: 1.5rem;
              border: 0.5px solid #4d4d4d;
              border-radius: 0.25rem;
              .MuiChip-label {
                @include body2;
                color: #212121;
                padding: $atom 0.625rem $atom $tiny;
                font-weight: 400;
                font-size: 0.6875rem;
                line-height: 1rem;
              }
              .MuiSvgIcon-root {
                height: $medium;
                width: $medium;
                fill: $greyText;
              }
            }
            .MuiChip-root.selected {
              background: #e3f2fd;
              border: 0.5px solid #0d47a1;
              .MuiChip-label {
                color: #0d47a1;
              }
              .MuiSvgIcon-root {
                fill: #0d47a1;
              }
            }
          }

          .MuiFilledInput-input {
            min-height: $xlarge;
            @include body2;
            background: none;
            &:hover {
              -webkit-text-fill-color: rgba(0, 0, 0, 1) !important;
            }
          }
        }
        &:focus-within {
          border: $borderline solid $white100;
          border-bottom: $borderline solid $grey95;
          background-color: $white100;
        }
      }
      > .na-body-toline.disabled-from-line {
        display: flex;
        align-items: center;
        opacity: 0.8;
        color: $grey30;
        cursor: auto;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1rem;
      }
      > .na-body-toline.to-address {
        input {
          text-indent: 0%;
        }
        input::placeholder {
          @include placeholderStyle;
        }
      }
      > .na-body-toline-reply {
        margin-left: 0.5625rem;
        height: $xlarge;
        white-space: nowrap;
      }
      > .na-body-activity-type-line {
        .drop-menu {
          padding-left: $medium;
          padding-right: 0.625rem;
          width: 100%;
          .body2 {
            width: 100%;
            text-transform: capitalize;
            color: $primary;
          }
        }
        .drop-menu svg {
          all: unset;
          width: 0.875rem;
          height: 0.5rem;
        }
        .drop-menu {
          .append-icon {
            height: $large;
            width: $large;
            border-radius: 50%;
            background-color: $grey90;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 0.1875rem;
            flex-shrink: 0;
            svg {
              width: $medium;
            }
          }
        }
      }
      > .na-body-activity-type-line.disabled {
        pointer-events: none;
        .drop-menu {
          .body2 {
            color: $grey50;
          }
        }
        .drop-menu svg {
          fill: $grey50;
        }
      }
    }
    > .na-body-title {
      height: $xlarge;
    }
    > .hyperlink-modal-container {
      position: absolute;
      z-index: 10;
      height: 90%;
      width: 100%;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      cursor: pointer;
      visibility: hidden;
      opacity: 0;
      transition: all 0.35s ease-in;
    }
    > .hyperlink-modal-container.is-visible {
      visibility: visible;
      opacity: 1;
      .modal-dialog {
        position: relative;
        width: 80%;
        max-width: 80%;
        background: $white100;
        overflow: auto;
        cursor: default;
        border: 0.063rem solid $backgroundAW;
        box-shadow: 0rem 0.063rem 0.125rem rgba(0, 0, 0, 0.15);
        border-radius: 0.125rem;
      }
      .modal-dialog > * {
        padding: 1rem;
      }
      .modal-header {
        border-radius: 0.3125rem 0.3125rem 0 0;
        background-color: $white100;
        color: $grey40;
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.5rem;
        align-items: center;
        justify-content: space-between;
        span.modal-title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        display: flex;
        flex-direction: row;
        span.close-button {
          display: flex;
          flex-direction: row;
        }
      }
      .modal-content {
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: $link;
        p {
          overflow: auto;
          height: inherit;
          word-wrap: break-word;
        }
      }
      .modal-footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        > :first-child {
          margin-right: 0.5rem;
        }
      }
    }
    > .hyperlink-edit-modal-container {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      background: rgba(0, 0, 0, 0.5);
      cursor: pointer;
      visibility: hidden;
      opacity: 0;
      transition: all 0.35s ease-in;
    }
    > .hyperlink-edit-modal-container.is-visible {
      visibility: visible;
      opacity: 1;
      .modal-dialog {
        position: relative;
        width: 50%;
        max-width: 50%;
        background: $white100;
        overflow: auto;
        cursor: default;
        border: 0.063rem solid $backgroundAW;
        box-shadow: 0rem 0.063rem 0.125rem rgba(0, 0, 0, 0.15);
        border-radius: 0.125rem;
      }
      .modal-dialog > * {
        padding: 1rem;
      }
      .modal-header {
        border-radius: 0.3125rem 0.3125rem 0 0;
        background-color: $white100;
        color: $grey40;
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.5rem;
        align-items: center;
        justify-content: space-between;
        span.modal-title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        display: flex;
        flex-direction: row;
        span.close-button {
          display: flex;
          flex-direction: row;
        }
      }
      .modal-content {
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        padding-top: 0rem;
        padding-bottom: 0rem;

        .modal-form {
          display: flex;
          flex-direction: column;

          label {
            font-style: normal;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1.25rem;
            display: flex;
            align-items: flex-end;
            color: $grey40;
          }
          input {
            margin-bottom: 1rem;
            min-height: 2.5rem;
            width: 100%;
            background-color: $grey98;
            border: 0.0625rem solid transparent;
            border-radius: 0.125rem;
            max-height: 4.875rem;
            display: flex;
            color: $primary;
            padding: 0.125rem 0.625rem 0.125rem 0.25rem;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.25rem;
          }
          input:last-child {
            color: $link;
          }
          input:focus,
          input:focus-within,
          input:focus-visible {
            border: $borderline solid $grey70;
            background-color: $grey93;
            outline: none;
          }
        }
      }
      .modal-footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        > :first-child {
          margin-right: 0.5rem;
        }
      }
    }
    > .na-body-message-card {
      display: flex;
      flex-direction: column-reverse;
      > .na-body-message-send {
        display: flex;
        align-items: center;
        justify-content: center;
        > .na-body-message-send-button {
          align-items: center;
          > .time-wrapper {
            display: flex;
            height: auto;
            width: auto;
            justify-content: center;
            align-items: center;
            column-gap: 10%;
            margin-right: 0.625rem;
            > .time-text-wrapper {
              display: flex;
              flex-direction: column;
              > div {
                font-family: $primaryFontFamily;
                font-style: normal;
                font-weight: 500;
              }
              > .time-text {
                color: $grey60;
                width: $xxlarge;
                height: $medium;
                font-size: 0.625rem;
                line-height: $medium;
                letter-spacing: 0 $tiny;
                text-transform: uppercase;
              }

              > .time-text-minutes {
                color: $black;
                width: 4.75rem;
                height: $base;
                font-size: $medium;
                line-height: $base;
              }
            }
          }
          position: absolute;
          right: $small;
          bottom: $small;
          display: flex;
          flex-direction: row;
          button {
            margin: 0 $tiny;
            border-radius: 0.25rem;
          }
          span:first-child {
            button {
              margin: 0 $tiny;
            }
          }
          span:last-child {
            button {
              margin-right: 0;
            }
          }

          > .send-and-close-group {
            margin-right: 1rem;
            button,
            span > button {
              margin: 0rem;
              border-radius: 0rem;
            }
            button:first-child,
            span:first-child > button {
              border-bottom-left-radius: 0.25rem;
              border-top-left-radius: 0.25rem;
              border-right: $borderline solid $white100;
              border-bottom-right-radius: 0rem;
              border-top-right-radius: 0rem;
            }
            button:last-child {
              border-bottom-right-radius: 0.25rem;
              border-top-right-radius: 0.25rem;
            }
            button.Mui-disabled {
              background: $babyBlue;
              color: $white100;
              opacity: 0.5;
            }
          }
        }
        > .na-body-message-send-closed {
          width: $semi;
          border-left: $borderline solid $white100;
          > button {
            padding: 0 $atom;
            border-radius: 0 $atom $atom 0;
          }
          > .send-closed-popup {
            background-color: $white100;
            position: absolute;
            border-radius: $atom;
            box-shadow: 0 $small $medium rgba(0, 0, 0, 0.1), 0 $tiny $semi rgba(0, 0, 0, 0.1);
            top: -$xlarge;
            right: 0;
            padding: $atom $tiny;
            > p {
              @include caption;
              white-space: nowrap;
              padding: $small $medium;

              &:hover {
                cursor: pointer;
                background-color: $grey93;
              }
            }
          }
        }
      }
    }

    .na-body-title.na-body-child {
      .sb-wrapper {
        .sb-input {
          font-weight: 400;
          font-size: 0.875rem;
          line-height: $semi;
        }
      }
    }

    .na-body-title.na-body-child.na-body-template-selector {
      display: flex;
      flex-direction: row;
      background-color: $white100;
      .sb-wrapper {
        width: 96%;
      }
      .na-body-cc-search {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .feed-item-attachments-container {
      border-bottom: 0.0625rem solid $borderBottom;
      margin-bottom: 0.5rem;
      .attachemnts-actions {
        padding-left: 1rem;
        margin-top: 0.45rem;
        margin-bottom: 0.45rem;
        a {
          font-style: normal;
          font-weight: 600;
          font-size: 0.875rem;
          line-height: 1.25rem;
          color: $babyBlue;
          text-decoration: none;
        }
      }
      .feed-item-attachments-loading-container {
        display: flex;
        .loading-wrapper {
          display: flex;
          flex-direction: row;
          position: relative;
          z-index: 1;
        }
      }
    }
  }
  .sb-input,
  .sb-input:disabled {
    background-color: $white100;
    border-bottom: 0.0625rem solid $grey95;
  }
}

.na-wrapper-card.activity-create-card.pop-out {
  position: fixed;
  right: 2.5rem;
  width: 40.375rem;
  left: 60%;
  z-index: 10;
}

.na-body-to-chip {
  border-radius: $atom;
  padding-right: 0.375rem;
  padding-bottom: 0;
  border: none;
  background-color: $grey96;
  fill: $grey60;
}

.MuiModal-root.MuiPopover-root.MuiMenu-overrides.activity-types-list-menu {
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper {
    width: 15%;

    .MuiMenuItem-root.MuiMenuItem-gutters {
      padding-left: $medium;
      padding-right: $medium;
    }
    .icon {
      height: $large;
      width: $large;
      border-radius: 50%;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.6875rem;

      svg {
        width: $medium;
      }
    }
  }
}

.activity-template-selector-autocomplete + .MuiAutocomplete-popper,
.activity-to-autocomplete + .MuiAutocomplete-popper {
  left: -$xlarge;
  width: 91% !important;

  .MuiPaper-root {
    .MuiAutocomplete-listbox {
      li.MuiAutocomplete-option {
        display: flex;
        flex-direction: row;
        gap: $small;
        padding: 0.5625rem $base;
        .contact-item-profile-tag {
          height: $larger;
          width: $larger;
          border-radius: 50%;
          background-color: $grey90;
          display: flex;
          align-items: center;
          justify-content: center;
          svg,
          img {
            fill: $grey60;
            width: 1.125rem;
            height: 1.125rem;
          }
        }

        div.contact-info-item {
          display: flex;
          flex-direction: column;
          span.contact-name {
            font-style: normal;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: $semi;
            display: flex;
            align-items: flex-end;
            color: $primary;
          }
          span.contact-email {
            font-style: normal;
            font-weight: 500;
            font-size: $medium;
            line-height: $base;
            display: flex;
            align-items: flex-end;
            color: $grey50;
          }
        }
      }
    }
  }
}

.MuiModal-root.MuiPopover-root.MuiMenu-overrides.template-types-list-menu {
  left: -1.875rem;
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper {
    width: 31.1%;
  }
}

.MuiTooltip-tooltip {
  max-width: 21rem;
  padding: 0.625rem 1rem;
  border-radius: 0.25rem;
}
