@use "../../../css/index" as *;

.uc-wrapper > .body > .megamenu-left {
  width: 14.875rem;
  border-right: $borderline solid $grey90;
  > .megamenu-wrapper {
    display: flex;
    flex-direction: column;
    background-color: $white100;
    padding: $large $small $base $small;
    width: 100%;
    height: 100vh;

    > .company-header {
      text-align: center;
    }
  }

  .connected-footer {
    margin-top: auto;
    margin-left: $small;

    &-text {
      @include caption;
      font-weight: 400;
      margin-bottom: $tiny;
    }
    &-img {
      height: $large;
      > svg {
        height: 100%;
      }
    }
  }
}
