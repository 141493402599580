@use "../../../../../../../../css/index" as *;

.states-action-confirmation-modal {
  .modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    p.modal-title {
      color: #212121;
      font-family: $sageFontFamily;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      margin: 0rem;
    }

    svg {
      height: 1.5rem;
      width: 1.5rem;
      cursor: pointer;
    }
  }

  .modal-body {
    p.subtitle {
      color: #212121;
      font-family: $sageFontFamily;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin: 0rem;
      margin-bottom: 0.5rem;
    }

    div.form-item-dropdown {
      margin-bottom: 1rem;
      .form-item-select {
        width: 100%;
        display: flex;
        height: 2.5rem;
        padding: 0rem 0rem;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;
        align-self: stretch;

        border-radius: 0.5rem;
        border: 0.0625rem solid #eee;
        background: #fff;

        fieldset {
          border: none;
        }

        #form-item-dropdown {
          padding-left: 0.75rem;
          padding-right: 0.75rem;
          color: #424242;
          font-family: $sageFontFamily;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }
      &:hover,
      &:focus,
      &:active {
        border-radius: 0.5rem;
        background: #fff;
      }
    }
    .form-item-text {
      margin-bottom: 1rem;
      p {
        color: #171717;
        font-family: $sageFontFamily;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        margin: 0px;
        margin-bottom: 0.5rem;
      }
      .form-item-input-field {
        width: 100%;
        border-radius: 0.5rem;
        border: 0.0625rem solid #eee;
        background: #fff;
        height: 2.75rem;
        padding: 0.625rem 0.75rem;

        input {
          color: #212121;
          font-family: $sageFontFamily;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }
      .form-item-input-field:after,
      .form-item-input-field:before {
        border: none;
      }
      .form-item-input-field:hover:after,
      .form-item-input-field:focus:after,
      .form-item-input-field:active:after,
      .form-item-input-field:hover:before,
      .form-item-input-field:focus:before,
      .form-item-input-field:active:before {
        border: none;
      }
    }
  }

  .modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.form-item-select-root.MuiPopover-root.MuiModal-root {
  ul {
    padding: 0.5rem !important;

    li {
      padding: 0.5rem;
      color: #212121;
      font-family: $sageFontFamily;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      gap: 0.5rem;
      div.MuiBox-root {
        height: 1rem;
        width: 1rem;
      }
    }
    li.Mui-selected,
    li:hover {
      background: #f5f5f5;
      border-radius: 0.5rem;
    }
  }
}
