@use "../../../css/index" as *;

.contact-popover-popper {
  margin-top: 0.5rem !important; /*Override the default popper margin*/
  z-index: 99 !important; /*Override required*/
  .contact-card-container {
    width: 18.75rem;
    height: 12.5rem;
    background: $white100;
    box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.15);
    border-radius: 0.5rem;
    .contact-profile-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 0.0625rem solid #eeeeee;
      padding: $large $large $base $large;
      height: 6.5rem;
      gap: 1rem;
      .contact-avatar-container {
        height: 3.5rem;
        min-width: 3.5rem;
        background: $white100;
        border: 0.0313rem solid black;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 1.6875rem;
        text-align: center;
        color: black;
      }
      .contact-profile-container {
        display: flex;
        flex-direction: column;
        gap: $atom;
        align-items: flex-start;
        justify-content: center;
        height: 4rem;
        .contact-name {
          display: flex;
          align-items: center;
          color: $black;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 11.25rem;
          text-transform: capitalize;
        }
        .contact-profile-email {
          font-style: normal;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.25rem;
          color: #212121;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 11.25rem;
          display: inline-block;
        }
        .contact-role {
          display: flex;
          align-items: center;
          color: #424242;
        }
        .contact-connection-container {
          display: flex;
          flex-direction: row;
          gap: 0.5rem;
          &:hover {
            cursor: pointer;
          }
          .tag {
            background: $grey90;
            border-radius: 0.125rem;
            height: 1.5rem;
            width: 1.5rem;
            font-style: normal;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1.25rem;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            color: $greyText;
          }
          .connection-name {
            font-style: normal;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1.25rem;
            display: flex;
            align-items: center;
            color: $greyText;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 9.375rem;
          }
        }
      }
    }
    .contact-contacts-row {
      display: flex;
      flex-direction: column;
      background: $white100;
      position: relative;
      z-index: 2;
      .contact-phone,
      .contact-email {
        display: flex;
        height: 2.5rem;
        gap: 1.125rem;
        align-items: center;
        border-bottom: 0.0625rem solid #eeeeee;
        padding: 0 $large 0 $large;
        span {
          font-style: normal;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.25rem;
          color: $greyText;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 15.3125rem;
          display: inline-block;
        }
        div {
          svg path {
            fill: $greyText;
          }
        }
        &.show-hover {
          &:hover {
            cursor: pointer;
          }
        }
      }
      .contact-phone:hover,
      .contact-email:hover,
      .contact-phone:focus,
      .contact-email:focus {
        background: $grey100;
        div {
          svg path {
            display: inline;
          }
        }
      }
    }
  }
}

.contact-popover-popper[data-popper-placement="top-start"] {
  margin-bottom: 0.5rem !important; /*Override the default popper margin*/
}
