@use "../../../../../css/index" as *;

.overlay-scrollbar-width-reset {
  .os-content-glue {
    /** override default 832px width for overlayscrollbar to parent div's width */
    width: 100% !important;
  }
}

.activity-attachment-tab {
  height: 100%;
  > .head {
    display: flex;
    padding: 0 $base;
    border-bottom: $borderline solid $grey94;
    > .head-container {
      flex-wrap: nowrap;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    .drop-menu {
      height: $xlarge;
    }
  }
  > .no-attachments {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    > .icon {
      > svg > path {
        width: 1.1rem;
        height: 1.19rem;
      }
      margin-bottom: 0.625rem;
    }
    > .text {
      color: $grey60;
    }
  }
}

.activity-attachment-item {
  padding: $small $base $medium;
  border-bottom: $borderline solid $grey94;
  cursor: pointer;
  &:hover {
    background-color: $grey96;
  }
  > .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > .container {
      display: flex;
      > p {
        @include body1;
      }
    }
    p {
      color: $black;
    }
  }
  > .body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      color: $grey60;
    }
  }
}
