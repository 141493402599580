@use "../../../css/index" as *;

.e-invoice-header-container {
  display: flex;
  padding: 0rem 1.5rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 100%;
  .menu-left-container {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    .menu-search-input {
      border-radius: 0.25rem;
      padding: 0 1.25rem;
      background: $grey100;
      > input.MuiInput-input.MuiInputBase-input.Mui-disabled  {
        font-family: $sageFontFamily;
        margin-left: -0.25rem;
        color: #757575;
        -webkit-text-fill-color: #757575;
        &::placeholder {
          color: #757575;
          -webkit-text-fill-color: #757575;
        }
      }
    }
  }
  .menu-right-container {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    height: 3.375rem;
    .menu-options-container {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 0.25rem;
      height: 100%;
      .option {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.25rem;
        min-width: 5.3125rem;
        align-self: stretch;
        cursor: pointer;
        .option-icon {
          display: flex;
          width: 1.6rem;
          height: 1.5rem;
          padding: 0.125rem 0.0625rem 0.15913rem 0.0625rem;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          > .userpic-btn {
            width: 1.5rem;
            height: 1.5rem;
            cursor: pointer;
          }
          > svg {
            path {
              fill: #4d4d4d;
            }
          }
        }
        .option-text {
          text-align: center;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: #4d4d4d;
        }
      }
      .option.active {
        border-radius: 0.5rem 0.5rem 0rem 0rem;
        background: #f4f4f4;
        border-bottom: 0.1875rem solid #000000;
        .option-text {
          color: #000000;
        }
        .option-icon {
          > svg {
            path {
              fill: #000000;
            }
          }
        }
      }
    }
  }
}

.e-invoice {
  .MuiInput-input,.MuiOutlinedInput-input {
    font-family: $sageFontFamily !important;
  }
  .MuiTabs-flexContainer button.MuiButtonBase-root.MuiTab-root {
    font-family: $sageFontFamily;
  }
  span{
    font-family: $sageFontFamily;
  }
  .gs-recent-suggestions-container{
    span{
      font-weight: 500;
    }
  }
  .container .default-styles-p {
    font-family: $sageFontFamily;
  }
}