@use "../../../../css/index" as *;

.activity-feed-wrapper.v2 {
  display: flex;
  flex-direction: column;
  height: 100%;
  > .head {
    height: 3.5rem;
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: $base;
    border-bottom: $borderline solid $grey94;
    > .head-grp {
      display: flex;
      flex-direction: row;
      gap: $small;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      > .left,
      > .right {
        display: flex;
        gap: $small;
      }
      > .left {
        flex-wrap: wrap;
      }
      .btn-transparent:disabled {
        background-color: transparent;
        svg {
          color: $primary;
        }
      }
      & .setup-automation {
        display: flex;
        align-items: center;
        & .column-icon {
          display: flex;
          align-items: center;
          padding-left: 0.375rem;
          > svg {
            margin-left: calc($small / 2);
            height: $base;
            width: $base;
            path {
              fill: $grey50;
            }
          }
        }
      }
      & .detail-icon-btn {
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        color: $grey90;
        gap: unset;
        grid-gap: unset;
        &:hover {
          background-color: transparent;
          & > svg > path {
            fill: $primary;
          }
        }
        & > svg > path {
          fill: $grey60;
        }
      }
      > .MuiIconButton-root {
        width: 1.75rem;
        height: 1.75rem;
        padding: $tiny;
        svg {
          width: $semi;
          height: $semi;
        }
        &.sm {
          svg {
            width: 1.125rem;
            height: 1.125rem;
          }
        }
        &.xs {
          svg {
            width: $medium;
            height: $medium;
          }
        }
      }
      & .completed-icon-btn {
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        color: $grey90;
        gap: unset;
        grid-gap: unset;
        &:hover {
          background-color: transparent;
          & > svg > g > path:last-child {
            fill: $primary;
          }
          & > svg > g > path:first-child {
            stroke: $primary;
          }
        }
        & > svg > g > path:last-child {
          fill: $grey60;
        }
      }
      > .MuiIconButton-root {
        width: 1.75rem;
        height: 1.75rem;
        padding: $tiny;
        svg {
          width: $semi;
          height: $semi;
        }
        &.sm {
          svg {
            width: 1.125rem;
            height: 1.125rem;
          }
        }
        &.xs {
          svg {
            width: $medium;
            height: $medium;
          }
        }
      }
      button.add-activity-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: $atom $small;
        height: $large;
        grid-gap: 0.65375rem;
        background: $primary;
        border-radius: $atom;
      }
    }
  }
  > .body {
    height: 1rem;
    flex: 1 1 auto;
    padding: $base $base;
    min-width: 0;
    overflow: auto;
    background-color: $grey100;
    > div {
      max-height: calc(100vh - 16.125rem);
    }
    .activity-card {
      max-height: 100%;
    }

    .infinite-scroll-component {
      overflow-x: hidden !important;
    }
  }
}
