@use "../../../../css/index" as *;

.activity-detail-right-panel-collapsible {
  padding: $larger $base;
  display: flex;
  flex-direction: column;
  background-color: $white100;
  > .bottom-buttons {
    margin-top: auto;
  }

  .top-buttons {
    > .right-panel-action-button {
      background-color: $babyBlue;
      border: $borderline solid $babyBlue;
      border-radius: $tiny;
      margin-bottom: $medium;
      color: $white100;
      width: 100%;
      svg {
        fill: $white100;
      }
      &:hover {
        background-color: $white100;
        color: $babyBlue;
        svg {
          fill: $babyBlue;
        }
      }
    }
    > .active {
      background-color: $selectionDark;
    }
  }
  > .right-drawer.MuiDrawer-root {
    > .MuiPaper-root {
      margin-top: 7.1rem;
      margin-right: 4rem;
      height: calc(100% - 7.1rem);
      background-color: $grey98;
      border-radius: $small 0px 0px $small;
      box-shadow: none;
      padding: $large;
      width: 26.4375rem;
      overflow-y: scroll;
      > .right-drawer-info-container {
        margin-bottom: $large;
        > .drawer-info-panel {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          > .drawer-sub-title {
            @include caption;
          }
          > .drawer-close-action {
            text-align: right;
          }
        }
        > .drawer-title {
          @include h3;
        }
      }
      > .right-drawer-info-textbox {
        padding: $medium $medium 0.625rem $medium;
        background: $backgroundCopilotHeader;
        border: $borderline solid $copilotText;
        border-radius: $small;
        margin-bottom: $large;
        > .drawer-info-text {
          @include body2;
          color: $copilotText;
        }
        > .drawer-info-action-container {
          display: flex;
          width: 100%;
          > .drawer-info-action {
            @include body3;
            color: $babyBlue;
            cursor: pointer;
            margin-left: auto;
            margin-right: $xlarge;
          }
        }
      }
      > .right-drawer-empty-panel {
        margin-top: auto;
        margin-bottom: auto;
        text-align: center;
        > .drawer-empty-title {
          @include h2;
        }
        > .drawer-empty-subtext {
          @include body1;
          color: $grey50;
        }
      }
    }
  }
}

.activities-wrapper {
  .activity-detail-right-panel-collapsible {
    > .right-drawer.MuiDrawer-root {
      > .MuiPaper-root {
        margin-top: 3.6rem;
        height: calc(100% - 3.6rem);
      }
    }
  }
}

.activities-wrapper.e-invoicing {
  .activity-detail-right-panel-collapsible {
    > .right-drawer.MuiDrawer-root {
      > .MuiPaper-root {
        margin-top: 5.95rem;
        margin-right: 5.5rem;
        height: calc(100% - 7.5rem);
      }
    }
  }
}
